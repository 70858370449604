import React from 'react'
import PropTypes from 'prop-types'
import Svg from 'react-inlinesvg'

import svgs from './geos'

export default function Geolines({
    name,
    src,
    className,
    colorClass = 'text-lightgrey',
}) {
    return name ? (
        <Svg
            src={svgs[name]}
            {...{
                className: `${className} pointer-events-none select-none ${colorClass}`,
            }}
        />
    ) : (
        <Svg
            src={src}
            {...{
                className: `${className} pointer-events-none select-none ${colorClass}`,
            }}
        />
    )
}

Geolines.propTypes = {
    name: PropTypes.string,
    src: PropTypes.string,
    className: PropTypes.string,
}
