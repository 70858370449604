import {
  GatsbyImage,
  getImage,
  getSrc,
  StaticImage,
} from "gatsby-plugin-image";
import React, { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Link from "@components/Link";
import Button from "@components/Button";

import { useParallax } from "react-scroll-parallax";

import { useInView } from "react-intersection-observer";
import { useLogoState } from "@context";
import AvaliableOn from "@components/AvailableOn";
import { useWishlistState } from "@context";

import "./styles.scss";

const ParallaxHero2 = ({ blade }) => {
  const { setLogoPosition } = useLogoState();
  const { setShow } = useWishlistState();

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: "-100px",
  });

  useEffect(() => {
    setLogoPosition(inView);
  }, [inView, setLogoPosition]);

  const { configs } = useStaticQuery(graphql`
    query StaticLogoQuery2 {
      configs: allDatoCmsAnglerGlobalSetting(filter: { locale: { eq: "en" } }) {
        nodes {
          id
          locale
          blackLogo {
            gatsbyImageData(placeholder: BLURRED)
            alt
          }
        }
      }
    }
  `);

  const blackLogo = getImage(configs?.nodes[0]?.blackLogo?.gatsbyImageData);

  const assets = {
    one: getSrc(blade.layerOne.gatsbyImageData),
    mobileOne: getImage(blade.mobileLayerOne.gatsbyImageData),
    two: getSrc(blade.layerTwo.gatsbyImageData),
    mobileTwo: getImage(blade.mobileLayerTwo.gatsbyImageData),
    three: getSrc(blade.layerThree.gatsbyImageData),
  };

  const refOne = useParallax({ speed: 25, translateY: 25 });
  const refOneMobile = useParallax({ speed: 25, translateY: 25 });
  // const refOneB = useParallax({ speed: 20, translateY: 20 });
  const refTwo = useParallax({ speed: 15, translateY: 15 });
  const refTwoMobile = useParallax({ speed: 15, translateY: 15 });
  const refThree = useParallax({ speed: [30, 60] });
  // const refFour = useParallax({ speed: 20, translateY: 20 });
  // const refFive = useParallax({ speed: 1, translateY: 1 });

  return (
    <>
      <section className=" relative w-full  h-[calc(100vh-60px)] flex items-center justify-center overflow-hidden bg-black">
        {/* Artificial BG */}
        <div className="bg-white h-3/4 w-full absolute top-0 left-0 hidden md:block" />
        {/* <div className="bg-black h-1/4 w-full absolute bottom-0 left-0" /> */}

        {/* assists height during scaling - duplicates largest image */}
        {/* <GatsbyImage image={assets.one} className="opacity-0 h-1/2" /> */}
        {/* 
        <StaticImage
          src="../../assets/geolines/bkg-black-lines-left@2x.png"
          className=" !absolute top-0 left-0 z-0 w-full"
          alt={"Geolines Graphic"}
        /> */}

        <div
          className="md:block hidden !absolute bottom-[0px] -top-[518px] short:-top-[486px] tall:-top-[384px] veryTall:top-auto z-20 -mb-10  min-w-[2040px] min-h-[410px]  "
          ref={refThree.ref}
        >
          {/* <GatsbyImage image={assets.three} alt={assets.three.alt} /> */}
          <img src={assets.three} alt={assets.three.alt} />
        </div>

        {/* Midground - Trees & Lake */}
        <div
          className="md:block hidden !absolute bottom-[0px] -top-[518px] short:-top-[486px] tall:-top-[384px] veryTall:top-auto  z-20 -mb-10  min-w-[2040px]"
          ref={refTwo.ref}
        >
          <div className="absolute  bg-white w-full h-20 z-10 -bottom-[4.9rem] hidden tall:block"></div>
          {/* <div className="absolute  bg-black w-full h-20 z-10 -bottom-[4.9rem] hidden tall:block"></div> */}
          {/* <GatsbyImage image={assets.two} alt={assets.two.alt} /> */}
          <img src={assets.two} alt={assets.two.alt} />
        </div>

        {/* Foremost - Rock */}
        <div
          className="md:block hidden !absolute bottom-[calc(96px-150px)] top-[24px] short:top-[48px] tall:top-[96px] veryTall:top-auto  z-20 -mb-10  min-w-[3840px] min-h-[410px]"
          ref={refOne.ref}
        >
          <div className="w-full h-full relative">
            <div className="absolute  bg-black w-full h-44 -bottom-36 tall:top-[calc(100%-0.25rem)] z-20"></div>
            {/* <GatsbyImage
              loading="eager"
              image={assets.one}
              alt={assets.one.alt}
              className="min-w-[1024px] min-h-[410px]"
            /> */}
            <img
              src={assets.one}
              className="min-w-[1024px] min-h-[410px]"
              alt={assets.one.alt}
            />
          </div>
        </div>

        <div
          className="md:hidden block !absolute bottom-52  z-20 -mb-10  min-w-[768px] -top-20 tall:top-auto"
          ref={refTwoMobile.ref}
        >
          <div className="absolute  bg-white w-full h-[110%] z-0 bottom-[calc(100%-1rem)] hidden tall:block"></div>
          {/* <div className="absolute  bg-black w-full h-20 z-10 -bottom-[4.9rem] hidden tall:block"></div> */}
          <GatsbyImage
            image={assets.mobileTwo}
            alt={assets.mobileTwo.alt}
            loading="eager"
          />
        </div>
        {/* Foremost - Rock - MOBILE */}
        <div
          className="md:hidden block !absolute z-20 bottom-40 -mb-10  min-w-[768px] top-[96px] tall:top-auto  "
          ref={refOneMobile.ref}
        >
          <div className="w-full h-full relative">
            <div className="absolute hidden tall:block  bg-black w-full h-full -bottom-full tall:top-[calc(100%-0.25rem)] z-20"></div>
            <GatsbyImage
              loading="eager"
              image={assets.mobileOne}
              alt={assets.mobileOne.alt}
              className=""
            />
          </div>
        </div>

        <div
          // ref={refOneB.ref}
          className="black-gradient-bg-socials w-full lg:flex items-center justify-center flex-col z-30 !absolute hidden  -bottom-3 -mt-10"
        >
          {blade.addButton && (
            <Button
              {...{
                color: "primary",
                clickHandler: () => setShow(true),
                className: "!text-2xl lg:!text-3xl",
              }}
            >
              {blade.heroButtonText}
            </Button>
          )}

          {blade.enableAvailablePlatforms && (
            <AvaliableOn
              {...{
                platforms: blade.platforms,
                socials: blade.socials,
                dateAvailable: blade?.dateAvailable,
              }}
            />
          )}
        </div>
      </section>
      <div className="w-full flex items-center justify-center flex-col relative lg:hidden bg-black -mt-20 z-30">
        {blade.addButton && (
          <Button
            {...{
              color: "primary",
              clickHandler: () => setShow(true),
              className: "!text-2xl lg:!text-3xl -mt-8 ",
            }}
          >
            {blade.heroButtonText}
          </Button>
        )}

        {blade.enableAvailablePlatforms && (
          <AvaliableOn
            {...{
              platforms: blade.platforms,
              socials: blade.socials,
              dateAvailable: blade?.dateAvailable,
            }}
          />
        )}
      </div>
    </>
  );
};

export default ParallaxHero2;
