import React, { useEffect, useState } from "react";
import cx from "classnames";
import { useSwipeable } from "react-swipeable";

import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

import Title from "@components/Title";
import Paragraph from "@components/Paragraph";
import CarouselItem from "@components/Carousel/CarouselItem";

import "./styles.scss";

export default function Carousel({
  title,
  body,
  items,
  type,
  className,
  locale,
  global,
}) {
  const [index, setIndex] = useState(0);
  const [direction, setDirection] = useState(1);
  /* eslint-disable no-unused-vars */
  const [active, setActive] = useState(true);
  const [paused, setPaused] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) updateIndex(index + 1);
    }, 10000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(index + 1),
    onSwipedRight: () => updateIndex(index - 1),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const updateIndex = (newIndex) => {
    if (newIndex < 0) newIndex = items.length - 1;
    else if (newIndex >= items.length) newIndex = 0;

    setIndex(newIndex);
  };

  const navigate = ({ forceDirection = null }) => {
    const offset =
      window.innerWidth > 768 ? (window.innerWidth > 1280 ? 2 : 2) : 1;

    if (!forceDirection) {
      if (index === 1 && direction === 0) {
        setDirection(1);
      } else if (index === items.length - offset && direction === 1) {
        setDirection(0);
      }
    }

    if (forceDirection === 0 || (forceDirection === null && direction === 0))
      setIndex(Math.max(0, index - 1));
    if (forceDirection === 1 || (forceDirection === null && direction === 1))
      setIndex(Math.min(items.length - (offset - 1), index + 1));
  };

  const handlePrevClick = () => {
    setActive(false);
    navigate({ forceDirection: 0 });
    setTimeout(() => {
      setActive(true);
    }, 5000);
  };

  const handleNextClick = () => {
    setActive(false);
    navigate({ forceDirection: 1 });
    setTimeout(() => {
      setActive(true);
    }, 5000);
  };

  const dummySlide = (
    <div className="pr-10 box-border group">
      <div className="news-carousel__card md:col-span-2 xl:col-span-1 px-8 pb-16 pt-40 lg:pt-48 xl:pt-52 text-left relative box-border h-full overflow-hidden">
        <span className="pointer-events-none opacity-0">dummy</span>
      </div>
    </div>
  );

  const result =
    title || body ? (
      <div
        {...{
          className: cx(
            "carousel max-w-screen-xl w-11/12 mx-auto overflow-visible",
            className
          ),
          onMouseEnter: () => setPaused(true),
          onMouseLeave: () => setPaused(false),
        }}
        {...handlers}
      >
        <div className="relative z-20">
          <div className="grid grid-cols-12 relative lg:gap-4">
            <div className="col-span-full lg:col-span-3 flex justify-between flex-col mb-2 lg:mb-0">
              <div>
                {title && (
                  <Title
                    {...{
                      type: 1,
                      className: "xl:text-6xl lg:text-5xl md:text-5xl text-3xl",
                    }}
                  >
                    {title}
                  </Title>
                )}
                {body && <Paragraph>{body}</Paragraph>}
              </div>
              <div className="flex flex-row flex-nowrap mt-10 rounded-full overflow-hidden lg:mt-0 w-fit bg-offwhite">
                {items.length &&
                  items.map((item, i) => (
                    <span
                      key={i}
                      className={`${
                        i === index ? `bg-primary rounded-full` : `bg-offwhite`
                      } w-6 h-1 block transition-colors`}
                    />
                  ))}
              </div>
            </div>
            <div className="col-span-full lg:col-span-9 xl:col-span-13 flex flex-row flex-nowrap cursor-pointer">
              <div
                className={`carousel__slider w-full grid grid-flow-col grid-cols-1 overflow-x-visible mt-4 transition duration-500 slide-${index}`}
              >
                {items.map((item, i) => (
                  <CarouselItem
                    {...{
                      key: i,
                      type,
                      data: item,
                      index,
                      locale,
                      i,
                      global,
                    }}
                  />
                ))}
                {(() => {
                  const slides = [];
                  for (let i = 5; i > items.length; i--) {
                    slides.push(dummySlide);
                  }

                  return slides;
                })()}
              </div>
            </div>
          </div>
          <div className="hidden lg:absolute left-0 bottom-8 lg:flex flex-row gap-4">
            {index > 0 && (
              <div
                onClick={() => handlePrevClick()}
                className="w-12 h-12 rounded-full bg-black flex items-center justify-center relative cursor-pointer group"
              >
                <AiOutlineLeft className="text-white w-6 h-6" />
                <span className="w-[120%] h-[120%] border border-primary border-dashed absolute rounded-full group-hover:opacity-100 transition-all opacity-0" />
              </div>
            )}
            {index < items.length - 1 && (
              <div
                onClick={() => handleNextClick()}
                className="w-12 h-12 rounded-full bg-black flex items-center justify-center relative cursor-pointer group"
              >
                <AiOutlineRight className="text-white w-6 h-6" />
                <span className="w-[120%] h-[120%] border border-primary border-dashed absolute rounded-full group-hover:opacity-100 transition-all opacity-0" />
              </div>
            )}
          </div>
        </div>
      </div>
    ) : (
      <div
        {...{
          className: cx(
            "structured-carousel max-w-screen-xl mx-auto bg-white overflow-visible",
            className
          ),
          onMouseEnter: () => setPaused(true),
          onMouseLeave: () => setPaused(false),
        }}
        {...handlers}
      >
        <div className="relative z-20">
          <div className="grid grid-cols-12 relative">
            <div className="col-span-full lg:col-span-9 xl:col-span-13 flex flex-row flex-nowrap cursor-pointer">
              <div
                className={`carousel__slider grid grid-cols-1 grid-flow-col overflow-x-visible mt-4 transition duration-500 slide-${index}`}
              >
                {items.map((item, i) => (
                  <CarouselItem
                    {...{
                      key: i,
                      type: item?.model?.name || type,
                      data: item,
                      locale,
                      index,
                      i,
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="lg:absolute right-12 top-1/2 flex flex-row gap-4">
            {index > 0 && (
              <div
                onClick={() => handlePrevClick()}
                className="w-16 h-16 rounded-full bg-black flex items-center justify-center relative cursor-pointer group"
              >
                <AiOutlineLeft className="text-white w-8 h-8" />
                <span className="w-[120%] h-[120%] border border-primary border-dashed absolute rounded-full group-hover:opacity-100 transition-all opacity-0" />
              </div>
            )}
            {index < items.length - 1 && (
              <div
                onClick={() => handleNextClick()}
                className="w-16 h-16 rounded-full bg-black flex items-center justify-center relative cursor-pointer group"
              >
                <AiOutlineRight className="text-white w-8 h-8" />
                <span className="w-[120%] h-[120%] border border-primary border-dashed absolute rounded-full group-hover:opacity-100 transition-all opacity-0" />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  return result;
}
