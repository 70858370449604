import React, { forwardRef } from "react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import cx from "classnames";

import Title from "@components/Title";
import Paragraph from "@components/Paragraph";
import LinkWithIcon from "@components/LinkWithIcon";
import { useLightboxState } from "@context";

const AboutCard = forwardRef(({ gridItems, className }, ref) => {
  const {
    setLightboxImages,
    setLightboxOpen,
    setLightboxVideo,
    setActiveLightboxImage,
  } = useLightboxState();

  const handleLightbox = (index) => {
    const isVideo =
      gridItems[index]?.externalVideoAsset?.url ||
      gridItems[index]?.internalVideo?.video?.mp4Url
        ? true
        : false;

    setActiveLightboxImage(0);
    if (isVideo && gridItems[index]?.externalVideo)
      setLightboxVideo(gridItems[index].externalVideoAsset);
    if (isVideo && !gridItems[index]?.externalVideo)
      setLightboxVideo(gridItems[index].internalVideo.video);

    if (!isVideo) setLightboxImages([gridItems[index].gridImage]);

    setLightboxOpen(true);
  };
  return (
    <div
      ref={ref}
      className={cx(
        "about-carousel__card lg:col-span-3 xl:col-span-3 lg:px-8 text-left relative box-border w-full h-full overflow-hidden",
        className
      )}
    >
      <div className="relative flex flex-col gap-6 lg:grid lg:grid-cols-2 w-full">
        {gridItems.map(
          (
            {
              gridType,
              gridImage,
              gridTitle,
              gridText,
              externalVideo,
              externalVideoAsset,
              internalVideo,
            },
            index
          ) => {
            switch (gridType) {
              case "image":
                const image = getImage(gridImage);
                return (
                  <div
                    key={index}
                    className={cx(`relative flex items-center justify-center`, {
                      "lg:mr-16 2xl:mr-20": index === 0,
                      "lg:ml-16 2xl:ml-20": index > 0 && index <= 2,
                      "lg:ml-12 2xl:ml-26": index === 3,
                    })}
                  >
                    <GatsbyImage
                      image={image}
                      objectFit="cover"
                      alt={gridImage.alt ? gridImage.alt : `grid image`}
                      className="lg:h-full lg:w-full"
                    />
                    <div className="text-white w-12 h-12 z-30 absolute p-3 border-white border bg-black top-1/2 left-1/2 bg-opacity-40 rounded-full -translate-y-1/2 -translate-x-1/2 hover:scale-105 transition-transform flex items-center justify-center cursor-pointer">
                      <LinkWithIcon
                        {...{
                          icon: "plus",
                          clickHandler: () => handleLightbox(index),
                          color: "white",
                          className: "",
                        }}
                      />
                    </div>
                  </div>
                );
              case "video":
                const thumbnailUrl = externalVideo
                  ? externalVideoAsset.thumbnailUrl
                  : internalVideo.video.thumbnailUrl;
                return (
                  <div
                    key={index}
                    className={cx(`relative flex items-center justify-center`, {
                      "lg:mr-16 2xl:mr-20": index === 0,
                      "lg:ml-16 2xl:ml-20": index > 0 && index <= 2,
                      "lg:ml-12 2xl:ml-26": index === 3,
                    })}
                  >
                    <img
                      src={thumbnailUrl}
                      alt={
                        externalVideoAsset?.title
                          ? externalVideoAsset?.title
                          : ``
                      }
                      className={"lg:w-full lg:h-full"}
                    />
                    <div className="text-white w-12 h-12 z-30 absolute p-3 border-white border bg-black top-1/2 left-1/2 bg-opacity-40 rounded-full -translate-y-1/2 -translate-x-1/2 hover:scale-105 transition-transform flex items-center justify-center cursor-pointer">
                      <LinkWithIcon
                        {...{
                          icon: "play",
                          clickHandler: () => handleLightbox(index),
                          color: "white",
                          className: "",
                        }}
                      />
                    </div>
                  </div>
                );
              case "blank":
                return <div key={index} />;
              case "text":
                return (
                  <div key={index} className="!pointer-events-none">
                    <Title
                      {...{
                        type: 2,
                        color: "white",
                        className: "mt-2 pointer-events-none",
                      }}
                    >
                      {gridTitle}
                    </Title>
                    <Paragraph
                      {...{
                        color: "white",
                        className: "pointer-events-none",
                      }}
                    >
                      {gridText}
                    </Paragraph>
                  </div>
                );
              default:
                return <div key={index} />;
            }
          }
        )}
        {/* <div className={cx(`!pointer-events-none`, {
                })}>
                    <Title {...{
                        type: 2,
                        color: 'white',
                        className: 'mt-2 pointer-events-none'
                    }}>{title}</Title>
                    <Paragraph {...{
                        color: 'white',
                        className: 'pointer-events-none'
                    }}>{body}</Paragraph>
                </div>
                {
                    images.map((img, i) => {
                        const isVideo = img.video ? true : false;
                        const image = !isVideo && getImage(img.gatsbyImageData);

                        return (
                            <div className={cx('', {})}>
                                <div className="relative flex items-center justify-center">
                                    {!isVideo ? (
                                        <GatsbyImage key={i} image={image} className="" alt={img?.gatsbyImageData?.alt} objectFit="cover" />
                                    ) : (
                                        <img src={img.video.thumbnailUrl} className="" />
                                    )}
                                    <LinkWithIcon {...{
                                        icon: isVideo ? 'play' : 'plus',
                                        clickHandler: () => handleLightbox(i),
                                        color: 'white',
                                        className: 'text-white w-12 h-12 z-30 absolute p-3 border-white border bg-black top-1/2 left-1/2 bg-opacity-40 rounded-full -translate-y-1/2 -translate-x-1/2 hover:scale-105 transition-transform'
                                    }} />
                                </div>
                            </div >
                        )
                    })
                } */}
      </div>
    </div>
  );
});

export default AboutCard;
