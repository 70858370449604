import React, { Fragment } from "react";
import BackgroundImage from "gatsby-background-image";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";

import { useLightboxState } from "@context";

import Title from "@components/Title";
import Paragraph from "@components/Paragraph";
import Link from "@components/Link";
import Icon from "@components/Icon";

const VideoEmbed = ({ background, video, className }) => {
  const { setLightboxImages, setLightboxVideo, setLightboxOpen } =
    useLightboxState();
  const bg = convertToBgImage(getImage(background));

  const handleVideoClick = () => {
    setLightboxImages([]);
    setLightboxVideo(video);
    setLightboxOpen(true);
  };

  return (
    <div
      className={`group w-full pt-[56.25%] relative cursor-pointer ${className}`}
      onClick={handleVideoClick}
    >
      <BackgroundImage
        {...bg}
        className="w-full h-full absolute top-0 left-0"
        style={{ position: "absolute" }}
      >
        <div className="block p-2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 border border-dashed border-primary rounded-full group-hover:scale-110 origin-center transition-all">
          <span className="block w-20 h-20 bg-black rounded-full">
            <Icon
              {...{
                name: "play",
                className:
                  "text-white w-5 h-5 ml-0.5 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2",
              }}
            />
          </span>
        </div>
      </BackgroundImage>
    </div>
  );
};

const CreatorHighlights = ({
  block: { title, body, featuredCreator, creators },
  className,
  locale,
}) => {
  return (
    <div className="w-11/12 max-w-7xl mx-auto relative z-20 text-center lg:text-left">
      <Title
        {...{
          type: 2,
          color: "black",
          className: "m-0 mb-4 text-5xl lg:text-6xl xl:text-6xl leading-none",
        }}
      >
        {title}
      </Title>
      {body && <Paragraph className="!text-black">{body}</Paragraph>}

      {featuredCreator ? (
        <div className="grid grid-cols-1 lg:grid-cols-5 lg:gap-20 p-8 mt-8 bg-light">
          <div className="hidden lg:block col-span-1 lg:col-span-3 order-first lg:order-last">
            <VideoEmbed
              background={featuredCreator?.videoFallbackImage}
              video={featuredCreator?.video}
            />
          </div>
          <div className="col-span-2 order-last lg:order-first flex flex-col items-start justify-start">
            <div className="flex items-center justify-center lg:justify-start mx-auto lg:mx-0 mb-8 lg:mb-0">
              <div>
                <span className="block p-2 border border-dashed border-primary rounded-full">
                  <GatsbyImage
                    image={getImage(featuredCreator?.profileImage)}
                    alt={featuredCreator?.name}
                    className="w-32 md:w-40 rounded-full"
                  />
                </span>
              </div>
              <div className="flex flex-col items-start ml-4 md:ml-8">
                <span className="block font-black text-lg text-primary uppercase">
                  Introducing
                </span>
                <span className="block pb-3 mb-3 font-black text-lg uppercase leading-none text-black border-b border-offwhite border-opacity-20">
                  {featuredCreator?.name}
                </span>

                {featuredCreator?.socialMedia?.length > 0 ? (
                  <div className="flex items-center justify-start -m-2">
                    {featuredCreator?.socialMedia?.map(
                      ({ socialMediaType, socialLink }, i) => (
                        <Link
                          external={true}
                          to={socialLink}
                          key={i}
                          className="group p-2"
                        >
                          <Icon
                            name={socialMediaType}
                            className="block w-6 h-auto fill-offwhite group-hover:fill-primary transition-all"
                          />
                        </Link>
                      )
                    )}
                  </div>
                ) : null}
              </div>
            </div>

            <VideoEmbed
              background={featuredCreator?.videoFallbackImage}
              video={featuredCreator?.video}
              className="lg:hidden"
            />

            {featuredCreator?.excerpt ? (
              <Paragraph className="mt-4 md:mt-8 !text-base !text-black">
                {featuredCreator.excerpt}
              </Paragraph>
            ) : null}

            <div className="flex items-center justify-center lg:justify-start text-center lg:text-left mx-auto lg:mx-0">
              <Link
                {...{
                  to: `/creators/${featuredCreator?.pageSlug}`,
                  type: "button",
                  className: "inline-block mt-8 mx-auto lg:mx-0",
                }}
              >
                Read more
              </Link>
            </div>
          </div>
        </div>
      ) : null}

      <div className="grid grid-cols-2 xl:grid-cols-4 gap-8 mt-8">
        {creators?.map(({ name, pageSlug, profileImage, socialMedia }, i) => (
          <div key={i} className="bg-light p-2 text-center sm:text-left">
            <div className="flex flex-col sm:flex-row items-center sm:items-start p-6 border border-dashed border-primary">
              <div>
                <GatsbyImage
                  image={getImage(profileImage)}
                  alt={name}
                  className="w-14 mb-4 mx-auto sm:mb-0 sm:ml-0 sm:mr-6 rounded-full"
                />
              </div>
              <div className="flex flex-col items-start justify-start overflow-hidden truncate">
                <span className="inline-block w-full mb-4 font-black text-black uppercase truncate">
                  {name}
                </span>

                {socialMedia?.length > 0 ? (
                  <div className="flex items-center justify-start mb-4 -m-2">
                    {socialMedia?.map(({ socialMediaType, socialLink }, i) => (
                      <Fragment key={i}>
                        <Link
                          to={socialLink}
                          external={true}
                          className="group p-2"
                        >
                          <Icon
                            name={socialMediaType}
                            className="block w-4 h-auto fill-offwhite group-hover:fill-primary transition-all"
                          />
                        </Link>
                      </Fragment>
                    ))}
                  </div>
                ) : null}

                <Link
                  to={`/creators/${pageSlug}`}
                  className="mx-auto sm:mx-0 pb-1.5 font-display ru-countach-font font-semibold text-lg text-primary uppercase border-b border-primary"
                >
                  Read more
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CreatorHighlights;
