import React from "react";
import PropTypes from "prop-types";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import cx from "classnames";
import { Link as GatsbyLink } from "gatsby";
import Svg from "react-inlinesvg";

import Button from "@components/Button";
import Title from "@components/Title";
import Paragraph from "@components/Paragraph";
import { isToday, isYesterday } from "@utils/dates";
import { GetLocaleButtons } from "@staticQueries/localeButtons";

export default function NewsCard({
  meta,
  heroImage,
  title,
  slug,
  excerpt,
  className,
  type = "news",
  icon,
  locale = "en",
}) {
  const backgroundImage = getImage(heroImage);
  const date = meta?.firstPublishedAt ? new Date(meta.firstPublishedAt) : null;
  const {
    config: {
      node: { newsButtonText, guidesButtonText },
    },
  } = GetLocaleButtons(locale);
  const resolvedDate = date
    ? isToday(date)
      ? "Today"
      : isYesterday(date)
      ? "Yesterday"
      : meta.firstPublishedAt
    : null;

  return (
    <div className={cx(`pr-10 box-border group relative`, className)}>
      <GatsbyLink to={slug}>
        <div
          className={cx(
            `news-carousel__card md:col-span-2 xl:col-span-1 px-8 pb-16 pt-40 lg:pt-48 xl:pt-52 text-left relative box-border h-full overflow-hidden !pointer-events-none`
          )}
        >
          <div className="relative z-20 h-full flex items-start justify-end flex-col">
            {resolvedDate && (
              <span className="text-white tracking-widest font-body">
                {resolvedDate}
              </span>
            )}
            <Title
              {...{
                type: 3,
                size: "4xl",
                color: "white",
                className: "my-4",
              }}
            >
              {title}
            </Title>
            <Paragraph
              {...{
                color: "white",
                className: "mb-4",
              }}
            >
              {excerpt}
            </Paragraph>
            <Button
              {...{
                className: "mt-4 block max-w-[250px] mr-auto ",
                color: "primary",
              }}
            >
              {type === "guides" ? guidesButtonText : newsButtonText}
            </Button>
          </div>
          <div className="absolute w-full h-full bg-black opacity-30 group-hover:opacity-60 transition-opacity top-0 left-0 z-10 duration-500 leading" />
          <GatsbyImage
            image={backgroundImage}
            alt={heroImage?.alt ? heroImage.alt : `Grid Image`}
            className="!absolute bottom-0 left-0 w-full !z-0 h-full group-hover:scale-105 duration-500 transition-transform !pointer-events-none"
          />

          {icon ? (
            <div className="clip-tag w-20 h-20 bg-primary absolute top-0 right-0 z-10">
              <Svg
                src={icon}
                className="w-6 h-auto absolute top-2 right-2.5 text-black"
              />
            </div>
          ) : null}
        </div>
      </GatsbyLink>
    </div>
  );
}

NewsCard.propTypes = {
  meta: PropTypes.shape({
    createdAt: PropTypes.string,
  }),
  // heroImage
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  className: PropTypes.string,
};
