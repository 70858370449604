import { useStaticQuery, graphql } from "gatsby";
import React, { useEffect, useState } from "react";

import { StaticImage } from "gatsby-plugin-image";
import Title from "@components/Title";
import Button from "@components/Button";
import NewsListingCard from "@components/NewsListingCard";
import { useCurrentWidth } from "@hooks/useCurrentWidth";
const NewsListing = ({
  block: { numberOfPostsToShow, numberOfPostsToShowMobile, showMoreButton },
  locale,
  global,
}) => {
  const { posts } = useStaticQuery(graphql`
    query AllPosts {
      posts: allDatoCmsNews(
        sort: { fields: meta___firstPublishedAt, order: DESC }
      ) {
        nodes {
          id
          locale
          locations
          pageSlug
          newsTitle
          excerpt
          category {
            categoryName
            locale
          }

          meta {
            firstPublishedAt(formatString: "DD/MM/YYYY")
          }
          heroImage {
            gatsbyImageData(placeholder: BLURRED)
            alt
          }
        }
      }
    }
  `);
  const [category, setCategory] = useState("All");
  const [show, setShow] = useState();
  const currentWidth = useCurrentWidth();

  useEffect(() => {
    //only want this to occur on mount hence nothing in dependency list
    if (currentWidth < 768) {
      setShow(numberOfPostsToShowMobile);
    } else {
      setShow(numberOfPostsToShow);
    }
  }, []);
  const sortedPosts = posts?.nodes?.filter(
    ({ locale: _locale }) => _locale === locale
  );

  const filteredPosts = sortedPosts?.filter(
    ({ category: { categoryName } }) =>
      category === "All" || category === categoryName
  );

  const categories = [
    "All",
    ...new Set(
      sortedPosts.map(({ category: { categoryName } }) => categoryName) || []
    ),
  ];

  return (
    <div className="w-11/12 mx-auto max-w-7xl relative z-20 py-20 pt-96 xs:pt-44 sm:!pt-20">
      <div className="absolute -top-8 lg:-top-12 left-0 bg-grey p-6 px-10 w-full xs:w-fit cursor-pointer z-40 overflow-hidden">
        <div className="flex justify-between items-center flex-col xs:flex-row flex-wrap gap-16">
          {categories.map((cat, i) => (
            <div
              key={i}
              className="flex flex-col relative whitespace-nowrap"
              onClick={() => setCategory(cat)}
            >
              <span
                className={`block font-display ru-countach-font text-2xl text-white uppercase ${
                  cat === category ? "opacity-100" : "opacity-50"
                }`}
              >
                {cat}
              </span>
              {cat === category && (
                <span className="block absolute bg-primary -bottom-1 h-1 w-5 mt-1" />
              )}
            </div>
          ))}
        </div>

        <StaticImage
          src="../../assets/geolines/bkg-black-lines-left@2x.png"
          className="!absolute min-w-[450px] top-0 left-0 !pointer-events-none h-full"
          alt="Geolines Graphic"
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-2 gap-y-4 mb-10">
        {[].concat(filteredPosts).map((post, i) => {
          return (
            <NewsListingCard
              {...{
                key: i,
                hide:
                  i >= show ||
                  (filteredPosts.find(({ id }) => id === post?.id) || [])
                    .length === 0,
                ...post,
                icon: global?.newsIcon?.url,
                locale,
              }}
            />
          );
        })}
      </div>
      {show < filteredPosts.length ? (
        <Button
          {...{
            className: "mx-auto block md:w-fit",
            color: "primary",
            clickHandler: () => setShow(show + 8),
          }}
        >
          {showMoreButton}
        </Button>
      ) : null}
    </div>
  );
};

export default NewsListing;
