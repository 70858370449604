import SocialIcons from "@components/SocialIcons";
import React from "react";

import LinkWithIcon from "@components/LinkWithIcon";

export default function AvaliableOn({ platforms, socials, dateAvailable }) {
  return (
    <div className="w-11/12 text-white relative z-30 py-4 lg:py-6 text-center px-12 flex items-center">
      {/* {dateAvailable ? (
        <h3 className="font-body uppercase text-xl tracking-widest text-white">
          {dateAvailable}
        </h3>
      ) : null} */}
      <div className="flex flex-col lg:flex-row gap-6 lg:gap-10 items-center justify-between relative w-full">
        <div className="flex flex-row gap-4 items-center justify-center flex-wrap">
          {dateAvailable ? (
            <h3 className="font-body uppercase text-xl tracking-widest text-white">
              {dateAvailable}
            </h3>
          ) : null}
          {platforms.map((platform, _index) => (
            <LinkWithIcon
              {...{
                key: _index,
                to: platform.gamePlatformLink,
                iconSrc: platform.platformLogo.url,
                external: true,
                className: "max-w-[6rem] max-h-[3rem]",
              }}
            />
          ))}
        </div>
        <SocialIcons
          {...{
            icons: socials,
            className:
              " flex-row flex-nowrap items-center justify-center gap-6 !text-white hidden lg:flex",
          }}
        />
      </div>
    </div>
  );
}
