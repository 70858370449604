import React from "react";
import PropTypes from "prop-types";
import Icon from "@components/Icon";
import cx from "classnames";
import Paragraph from "@components/Paragraph";

export default function WatchTrailerIcon({
  video,
  icon,
  className,
  clickHandler,
  thumbnail,
  headerText,
  ...rest
}) {
  return (
    <div
      className={cx("align-bottom group", className)}
      onClick={() => clickHandler()}
      {...rest}
    >
      <div className="flex flex-row flex-nowrap items-center justify-start gap-6 mb-4 max-w-[275px]">
        <span className="bg-primary w-full h-0.5 block" />
        <Paragraph
          {...{
            className: "uppercase whitespace-nowrap !text-xl !text-white",
          }}
        >
          {headerText ? headerText : "Watch Trailer"}
        </Paragraph>
      </div>

      <div className="relative flex items-center justify-center">
        {thumbnail && (
          <img
            src={thumbnail}
            className="w-full h-full object-cover bg-black opacity-80"
          />
        )}

        {icon && (
          <div className="border-2 border-white rounded-full absolute p-4 box-border flex items-center justify-center group-hover:scale-105 transition-all">
            <Icon
              {...{
                name: icon,
                className: "fill-current w-6 h-6",
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

WatchTrailerIcon.propTypes = {
  image: PropTypes.object,
  video: PropTypes.object,
  clickHandler: PropTypes.func,
  icon: PropTypes.string,
};
