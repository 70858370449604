import React, { useState } from "react";
import { parseISO, format } from "date-fns";

import Title from "@components/Title";
import Paragraph from "@components/Paragraph";
import Link from "@components/Link";
import Icon from "@components/Icon";
import Button from "@components/Button";

export default function TwitchSchedule({
  block: {
    title,
    body,
    buttonLink,
    buttonText,
    twitchIcon,
    loadMoreButtonText,
  },
  twitchSchedule,
}) {
  const [showFirst, setShowFirst] = useState(6);

  return (
    <div className="w-11/12 max-w-7xl mx-auto relative z-20">
      <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between">
        <div>
          <Title
            {...{
              type: 2,
              color: "black",
              className:
                "m-0 mb-4 text-5xl lg:text-6xl xl:text-6xl leading-none",
            }}
          >
            {title}
          </Title>
          {body && <Paragraph className="!text-black">{body}</Paragraph>}
        </div>

        {buttonLink && buttonText ? (
          <Link
            {...{
              download: true,
              type: "button",
              external: true,
              to: buttonLink,
              className:
                "mt-4 lg:mt-0 bg-primary text-xl xl:text-2xl text-black text-center",
            }}
          >
            <div className="flex items-center justify-center">{buttonText}</div>
          </Link>
        ) : null}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 items-center mt-8 md:mt-12">
        {twitchSchedule?.length > 0 ? (
          twitchSchedule?.map(
            ({ start_time, end_time, title, category }, index) => (
              <div
                key={index}
                className={`group grid grid-cols-3 ${
                  index > showFirst - 1 ? "hidden" : "block"
                }`}
              >
                <div className="p-2 relative bg-white">
                  <div className="flex flex-col py-4 px-2 border border-dashed border-primary text-center">
                    <span className="text-6xl font-display ru-countach-font font-bold text-black">
                      {format(parseISO(start_time), "dd")}
                    </span>
                    <span className="font-display ru-countach-font font-semibold text-xl uppercase">
                      {format(parseISO(start_time), "MMMM")}
                    </span>
                    <span className="text-sm">
                      {format(parseISO(start_time), "HH:mm")} -{" "}
                      {format(parseISO(end_time), "HH:mm")}
                    </span>
                    <span className="text-sm">GMT+1</span>
                  </div>

                  <div className="w-full h-full absolute top-0 left-0 bg-twitch pointer-events-none select-none opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all">
                    <Icon
                      name="twitch"
                      className="w-16 h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 fill-white"
                    />
                  </div>
                </div>
                <div className="flex flex-col col-span-2 p-4 py-6 pr-8 relative bg-black rounded-r-lg overflow-hidden">
                  <h3 className="block mb-3 text-sm font-semibold uppercase text-white">
                    {title}
                  </h3>
                  <span className="block mb-3 text-primary text-sm">
                    {category?.name}
                  </span>
                  <span className="text-white text-sm">
                    Watch on <strong>Twitch</strong>
                  </span>

                  <span className="block w-8 h-8 absolute top-1/2 left-full transform -translate-x-1/2 -translate-y-1/2 bg-light rounded-full pointer-events-none select-none" />
                </div>
              </div>
            )
          )
        ) : (
          <Title
            {...{
              type: 4,
              color: "black",
              className:
                "m-0 mb-4 text-5xl lg:text-6xl xl:text-6xl leading-none",
            }}
          >
            No upcoming streams
          </Title>
        )}
      </div>

      {showFirst < twitchSchedule?.length ? (
        <div className="flex items-center justify-center">
          <Button
            {...{
              clickHandler: () => setShowFirst(showFirst + 12),
              color: "primary",
              className:
                "mt-8 bg-primary text-xl xl:text-2xl text-black text-center",
            }}
          >
            {loadMoreButtonText}
          </Button>
        </div>
      ) : null}
    </div>
  );
}
