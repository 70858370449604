import React from "react";
import PropTypes from "prop-types";
import Hero from "@containers/Hero";
import ColorBlade from "@containers/ColorBlade";
import ImageBlade from "@containers/ImageBlade";
import ParallaxHero from "@containers/ParallaxHero";
import ParallaxHero2 from "@containers/ParallaxHero2";

export default function ContainerResolver({
  blades,
  locale,
  global,
  twitchSchedule,
}) {
  let stacking = 1;

  const Components = {
    DatoCmsAnglerParallaxHero: ParallaxHero,
    DatoCmsAnglerParallaxHeroV2: ParallaxHero2,
    DatoCmsAnglerHero: Hero,
    DatoCmsAnglerColorBlade: ColorBlade,
    DatoCmsAnglerImageBlade: ImageBlade,
  };

  return blades.map((blade, index) => {
    stacking = stacking * 1.5;
    const nextColor = blades[index + 1]?.backgroundColor || null;
    if (Components[blade.__typename]) {
      return React.createElement(Components[blade.__typename], {
        key: index,
        index,
        blade,
        locale,
        stack: stacking,
        global,
        twitchSchedule,
        nextColor,
      });
    }

    return null;
  });
}

ContainerResolver.propTypes = {
  blades: PropTypes.array,
  locale: PropTypes.string,
};
