import React, { useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import NewsListing from "@components/NewsListing";
import SingleNews from "@components/SingleNews";
import AboutReel from "@components/AboutReel";
import LatestNews from "@components/LatestNews";
import Newsletter from "@components/Newsletter";
import LatestSocial from "@components/LatestSocial";
import YoutubeVideos from "@components/YoutubeVideos";
import PressKit from "@components/PressKit";
import ImageGrid from "@components/ImageGrid";
import GuideListing from "@components/GuideListing";
import Faqs from "@components/Faqs";
import TwitchSchedule from "@components/TwitchSchedule";
import CreatorHighlights from "@components/CreatorHighlights";
import TextBox from "@components/TextBox";
import SupportForm from "@components/SupportForm";
import TrimPath from "@components/TrimPath";

import Divider from "@components/Divider";
import Geolines from "@components/Geolines";

export default function ColorBlade({
  blade: {
    __typename,
    backgroundColor,
    block,
    topCutaway,
    bottomCutaway,
    topCutawayColor,
    backgroundPaths,
    bottomCutawayColor,
    leftGeolines,
    lefthandGeolineColor,
    rightGeolines,
    righthandGeolineColor,
  },
  nextColor,
  locale,
  stack,
  margin,
  index,
  global,
  twitchSchedule,
}) {
  const bladeRef = useRef(null);

  const Components = {
    DatoCmsAnglerAboutReel: AboutReel,
    DatoCmsAnglerLatestNews: LatestNews,
    DatoCmsAnglerNewsletter: Newsletter,
    DatoCmsAnglerSingleNews: SingleNews,
    DatoCmsAnglerNewsListing: NewsListing,
    DatoCmsAnglerLatestSocial: LatestSocial,
    DatoCmsAnglerYoutubeVideo: YoutubeVideos,
    DatoCmsAnglerPressKit: PressKit,
    DatoCmsAnglerImageGrid: ImageGrid,
    DatoCmsAnglerGuideListing: GuideListing,
    DatoCmsAnglerFaq: Faqs,
    DatoCmsAnglerTwitchSchedule: TwitchSchedule,
    DatoCmsAnglerCreatorHighlight: CreatorHighlights,
    DatoCmsAnglerTextBox: TextBox,
    DatoCmsAnglerSupportForm: SupportForm,
  };

  return (
    <section
      {...{
        ref: bladeRef,
        className: `relative ${bottomCutaway ? "z-20" : ""}`,
      }}
    >
      <div className="overflow-hidden z-40">
        {topCutaway && (
          <Divider
            {...{
              name: topCutaway,
              className: `absolute -top-1 left-0 z-50 min-w-[850px]`,
              fill: topCutawayColor,
            }}
          />
        )}

        <div
          className={cx(`py-20 lg:py-32 relative`, {
            "bg-primary": backgroundColor === "primary",
            "bg-black": backgroundColor === "black",
            "bg-white": backgroundColor === "white",
            "bg-grey": backgroundColor === "gray",
            "bg-light": backgroundColor === "light",
          })}
        >
          {block.map((block, index) => {
            if (Components?.[block.__typename]) {
              return React.createElement(Components[block.__typename], {
                key: block.id,
                block,
                locale: locale,
                global,
                twitchSchedule,
              });
            } else {
              return "DEFAULT";
            }
          })}

          {leftGeolines ? (
            <Geolines
              {...{
                name: "geolines1",
                className:
                  "!absolute -left-10 -bottom-12 max-w-[750px] 2xl:max-w-[950px] z-10 opacity-75 !pointer-events-none",
                colorClass: cx({
                  "text-primary": lefthandGeolineColor === "primary",
                  "text-black": lefthandGeolineColor === "black",
                  "text-white": lefthandGeolineColor === "white",
                  "text-grey": lefthandGeolineColor === "gray",
                  "text-offwhite text-opacity-30":
                    lefthandGeolineColor === "offwhite",
                  "text-lightgrey": lefthandGeolineColor === "light",
                }),
              }}
            />
          ) : null}

          {rightGeolines ? (
            <Geolines
              {...{
                name: "geolines1",
                className:
                  "!absolute -right-10 -bottom-12 max-w-[750px] 2xl:max-w-[950px] opacity-75 transform origin-center -scale-x-100 z-10 !pointer-events-none",
                colorClass: cx({
                  "text-primary": righthandGeolineColor === "primary",
                  "text-black": righthandGeolineColor === "black",
                  "text-white": righthandGeolineColor === "white",
                  "text-grey": righthandGeolineColor === "gray",
                  "text-offwhite text-opacity-30":
                    righthandGeolineColor === "offwhite",
                  "text-lightgrey": righthandGeolineColor === "light",
                }),
              }}
            />
          ) : null}
        </div>

        {/* {rightGeolines || leftGeolines ? (
          <Divider
            {...{
              name: "texture1copy",
              className: `z-50 min-w-[850px] absolute bottom-0 left-0`,
              fill: `fill-black`,
              bg: `bg-transparent`,
            }}
          />
        ) : null} */}

        {bottomCutaway && (
          <Divider
            {...{
              name: bottomCutaway,
              className: `z-10 min-w-[850px]`,
              fill: bottomCutawayColor,
              bg: `bg-${nextColor}`,
            }}
          />
        )}
      </div>
      {backgroundPaths &&
        backgroundPaths.map(({ trailPath }) => (
          <TrimPath
            {...{
              name: trailPath,
            }}
          />
        ))}
    </section>
  );
}

ColorBlade.propTypes = {
  __typename: PropTypes.string,
  backgroundColor: PropTypes.string.isRequired,
  block: PropTypes.array,
  stack: PropTypes.number,
};

ColorBlade.defaultProps = {
  backgroundColor: "white",
};
