import React from 'react'

import getModelLink from '@utils/getModelLink'

import Title from '@components/Title'
import Paragraph from '@components/Paragraph'
import Link from '@components/Link'

export default function TextBox({
    block: { heading, body, externalLink, linkText, link, url },
    locale,
}) {
    return (
        <div className="w-11/12 max-w-4xl mx-auto relative z-20">
            <div className="flex flex-col items-center justify-center p-8 md:p-12 text-center bg-black">
                {heading ? (
                    <Title
                        {...{
                            type: 2,
                            size: '4xl',
                            color: 'white',
                            className: 'my-4 xl:text-5xl md:text-4xl text-3xl',
                        }}
                    >
                        {heading}
                    </Title>
                ) : null}

                {body ? (
                    <Paragraph
                        {...{
                            color: 'white',
                        }}
                    >
                        {body}
                    </Paragraph>
                ) : null}

                {(url || link) && linkText ? (
                    <Link
                        {...{
                            type: 'button',
                            to: externalLink
                                ? url
                                : getModelLink(
                                      link.pageSlug,
                                      locale,
                                      link?.model?.name
                                  ),
                            external: externalLink,
                            className:
                                'mt-8 !bg-white text-primary hover:text-white',
                        }}
                    >
                        {linkText}
                    </Link>
                ) : null}
            </div>
        </div>
    )
}
