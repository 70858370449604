import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export default function Checkbox({
    name,
    label,
    checked = false,
    changeHandler,
    className
}) {
    const handleChange = (e) => {
        e.preventDefault();
        typeof changeHandler === 'function' && changeHandler();
    }

    return (
        <div>
            <label htmlFor={name} onClick={(e) => handleChange(e)} className={cx(`flex items-center justify-start gap-4 flex-row text-grey`, className)}>
                <input
                    {...{
                        type: 'checkbox',
                        name,
                        checked,
                        className: 'hidden',
                        onChange: changeHandler
                    }} />
                <div className="border-[2px] p-1 shrink-0 border-primary w-6 h-6 rounded-full cursor-pointer">
                    <span className={`${checked ? 'bg-primary' : ''} w-full h-full block rounded-full`} />
                </div>
                {label && <span className="">{label}</span>}
            </label>
        </div>
    )
}

Checkbox.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    changeHandler: PropTypes.func.isRequired,
    className: PropTypes.string
}

Checkbox.defaultProps = {
    checked: false,
}