import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { GatsbyImage } from 'gatsby-plugin-image'
import Title from '@components/Title'
import Paragraph from '@components/Paragraph'

export default function FeaturesCard({
    title,
    body,
    image,
    background = 'black',
    textColor = 'gray',
    titleColor = 'white',
    className,
}) {

    return (
        <div className={cx(`p-4 pb-10 bg-black shadow-md w-fit flex flex-col max-w-[425px] mx-auto flex-nowrap text-white`, className)} >
            <GatsbyImage image={image} className="max-h-[400px] xl:h-[225px] h-full" alt={`Feature Card Image`} />
            <div className="">
                <Title {...{
                    type: 2,
                    color: 'white',
                    className: 'mt-6 mb-5 text-3xl md:text-3xl lg:!text-4xl xl:!text-4xl'
                }}>{title}</Title>
                <Paragraph {...{
                    className: 'mt-0',
                    color: 'white'
                }}>{body}</Paragraph>
            </div>
        </div>
    )
}

FeaturesCard.propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    image: PropTypes.object,
    background: PropTypes.string,
    textColor: PropTypes.string,
    titleColor: PropTypes.string,
}

FeaturesCard.defaultProps = {
    background: 'black',
    textColor: 'gray',
    titleColor: 'white',

}