import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useEffect } from 'react';
import { usePortalState } from '@context';
import Portal from '@hoc/Portal';

import Link from '@components/Link';
import Paragraph from '@components/Paragraph';
import Title from '@components/Title';
import { AiOutlineClose } from 'react-icons/ai';
import Cookies from 'js-cookie';


export default function PromoCard({ usePromo, promoImage, promoText, promoTitle, promoButtons, }) {

    const image = getImage(promoImage.gatsbyImageData);
    const { show, setShow } = usePortalState();

    const cookieString = `angler-promo-${promoTitle.replace(/[^A-Z0-9]+/ig, '-').toLowerCase()}`;

    useEffect(() => {
        if (!Cookies.get('angler-promo-cookie')) {
            setShow(true);
        }

        return () => {
            setShow(false);
        }
    }, [show, Cookies, setShow])

    const handlePromo = () => {
        if (Cookies.get('angler-promo-cookie')) {
        } else {
            Cookies.set('angler-promo-cookie', cookieString, {
                expires: 30
            });
            setShow(false);
        }
    }

    return usePromo && (
        <Portal {...{
            className: `${show ? 'block' : 'hidden'}`,
        }}>
            <div className={`max-w-[550px] bg-white w-11/12 text-white shadow-md relative`}>
                <span className="absolute top-4 right-4 p-2 bg-black z-50 cursor-pointer" onClick={() => handlePromo()}>
                    <AiOutlineClose className="text-white text-3xl" />
                </span>
                <GatsbyImage image={image} alt={promoImage.alt} />
                <div className="px-10 py-6 flex items-center flex-col justify-center text-center max-w-xs mx-auto w-full">
                    <Title {...{
                        type: 1,
                        size: '5xl',
                        color: 'black',
                        className: 'my-2'
                    }}>{promoTitle}</Title>
                    <Paragraph {...{
                        className: 'uppercase opacity-80 tracking-widest'
                    }}>{promoText}</Paragraph>
                    <div className="flex flex-col gap-6 mt-6 mb-2 w-full">
                        {promoButtons.map(({ buttonLink, buttonText, isExternal }, index) => <Link {...{
                            key: index,
                            type: 'button',
                            external: isExternal,
                            to: buttonLink,
                            className: '',
                            color: 'primary'
                        }}>{buttonText}</Link>)}
                    </div>
                </div>
            </div>
        </Portal>
    )
}