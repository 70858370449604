import { graphql, useStaticQuery } from "gatsby"

export const GetLocaleButtons = (locale = 'en') => {
    const { buttons } = useStaticQuery(graphql`
        query GlobalLocaleButtons {
            buttons: allDatoCmsAnglerGlobalSetting {
                edges {
                    node {
                        locale
                        newsButtonText
                        guidesButtonText
                    }
                }
            }
        }
    `)

    const config = buttons?.edges.find(
        ({ node: { locale: _locale } }) => _locale === locale
    )

    return { config }
}