import React from "react";
import GameFeatures from "@components/GameFeatures";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";
import Divider from "@components/Divider";

export default function ImageBlade({
  blade: {
    backgroundImage,
    block,
    topCutaway,
    bottomCutaway,
    topCutawayColor,
    bottomCutawayColor,
  },
  nextColor,
  locale,
}) {
  const background = getImage(backgroundImage.gatsbyImageData);
  const bg = convertToBgImage(background);

  const Components = {
    DatoCmsAnglerGameFeature: GameFeatures,
  };

  return (
    <section className="relative -mt-16 xl:-mt-20">
      {topCutaway && (
        <Divider
          {...{
            name: topCutaway,
            className: `absolute -top-1 left-0`,
            fill: topCutawayColor,
          }}
        />
      )}

      <BackgroundImage Tag="div" {...bg} className="py-32 relative">
        {block.map((block, index) => {
          if (Components[block.__typename]) {
            return React.createElement(Components[block.__typename], {
              key: block.id,
              block,
              locale: locale,
            });
          }
          return false;
        })}

        {bottomCutaway && (
          <Divider
            {...{
              name: "texture1",
              className: `absolute transform -scale-y-100 -bottom-1 left-0`,
              fill: bottomCutawayColor,
            }}
          />
        )}
      </BackgroundImage>
    </section>
  );
}
