import React, { useRef, useState } from 'react'

import Title from '@components/Title'
import Paragraph from '@components/Paragraph'
import Button from '@components/Button'
import Text from '@components/Inputs/Text'
import Select from '@components/Inputs/Select'

const statuses = {
    PENDING: 'pending',
    SUCCESS: 'success',
    FAILED: 'failed',
    LOADING: 'loading',
}

export default function SupportForm({
    block: {
        heading,
        body,
        nameLabel,
        emailLabel,
        discordLabel,
        platformLabel,
        issueLabel,
        subjectLabel,
        descriptionLabel,
    },
    locale,
}) {
    /* eslint-disable no-unused-vars */
    const formRef = useRef(null)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [discord, setDiscord] = useState('')
    const [platform, setPlatform] = useState('')
    const [issue, setIssue] = useState('')
    const [subject, setSubject] = useState('')
    const [description, setDescription] = useState('')
    const [accepted, setAccepted] = useState(false)

    const [status, setStatus] = useState(statuses.PENDING)

    const handleSubmit = (e) => {
        e.preventDefault()

        if (status !== statuses.LOADING && formRef?.current) {
            e.preventDefault()
            let formData = new FormData(formRef?.current)

            fetch('/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams(formData).toString(),
            })
                .then(() => setStatus(statuses.SUCCESS))
                .catch(() => setStatus(statuses.FAILED))
        }
    }

    return (
        <div
            id="support-form"
            className="w-11/12 max-w-7xl mx-auto relative z-20"
        >
            <div className="flex flex-col items-center justify-center max-w-7xl p-8 md:p-12 mx-auto text-center bg-black">
                {heading ? (
                    <Title
                        {...{
                            type: 2,
                            size: '4xl',
                            color: 'white',
                            className: 'my-4 xl:text-5xl md:text-4xl text-3xl',
                        }}
                    >
                        {heading}
                    </Title>
                ) : null}

                {body ? (
                    <Paragraph
                        {...{
                            color: 'white',
                        }}
                    >
                        {body}
                    </Paragraph>
                ) : null}

                {status !== statuses.SUCCESS ? (
                    <form
                        ref={formRef}
                        className={`mt-12 md:mt-20 ${status === statuses.LOADING
                                ? 'opacity-50 pointer-events-none'
                                : 'opacity-100'
                            }`}
                        name="supportForm"
                        method="POST"
                        data-netlify="true"
                        netlify-honeypot="bot-field"
                        onSubmit={handleSubmit}
                    >
                        <input
                            type="hidden"
                            name="form-name"
                            value="supportForm"
                        />

                        <label className="hidden">
                            {`Don’t fill this out if you're human:`}{' '}
                            <input name="bot-field" />
                        </label>

                        <div className="flex flex-wrap m-4 text-left text-white">
                            <div className="w-full md:w-1/2 p-4">
                                <Text
                                    {...{
                                        value: name,
                                        name: 'name',
                                        placeholder: nameLabel,
                                        required: true,
                                        changeHandler: (e) =>
                                            setName(e.target.value),
                                    }}
                                />
                            </div>
                            <div className="w-full md:w-1/2 p-4">
                                <Text
                                    {...{
                                        value: email,
                                        name: 'email',
                                        type: 'email',
                                        placeholder: emailLabel,
                                        required: true,
                                        changeHandler: (e) =>
                                            setEmail(e.target.value),
                                    }}
                                />
                            </div>
                            <div className="w-full md:w-1/2 p-4">
                                <Text
                                    {...{
                                        value: discord,
                                        name: 'discord',
                                        placeholder: discordLabel,
                                        changeHandler: (e) =>
                                            setDiscord(e.target.value),
                                    }}
                                />
                            </div>
                            <div className="w-full md:w-1/2 p-4">
                                <div className="flex -m-2">
                                    <div className="w-1/2 p-2">
                                        <Select
                                            {...{
                                                value: platform,
                                                name: 'platform',
                                                options: [
                                                    {
                                                        value: 'Windows 10',
                                                        label: 'Windows 10',
                                                    },
                                                    {
                                                        value: 'Epic',
                                                        label: 'Epic',
                                                    },
                                                    {
                                                        value: 'Steam',
                                                        label: 'Steam',
                                                    },
                                                ],
                                                placeholder: platformLabel,
                                                required: true,
                                                setValue: (value) =>
                                                    setPlatform(value),
                                            }}
                                        />
                                    </div>
                                    <div className="w-1/2 p-2">
                                        <Select
                                            {...{
                                                value: issue,
                                                name: 'issue',
                                                options: [
                                                    {
                                                        value: 'Technical Issue',
                                                        label: 'Technical Issue',
                                                    },
                                                    {
                                                        value: 'Bug Report',
                                                        label: 'Bug Report',
                                                    },
                                                    {
                                                        value: 'Apex Connect',
                                                        label: 'Apex Connect',
                                                    },
                                                ],
                                                placeholder: issueLabel,
                                                required: true,
                                                setValue: (value) =>
                                                    setIssue(value),
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="w-full p-4">
                                <Text
                                    {...{
                                        value: subject,
                                        name: 'subject',
                                        placeholder: subjectLabel,
                                        required: true,
                                        changeHandler: (e) =>
                                            setSubject(e.target.value),
                                    }}
                                />
                            </div>
                            <div className="w-full p-4">
                                <Text
                                    {...{
                                        value: description,
                                        name: 'description',
                                        placeholder: descriptionLabel,
                                        required: true,
                                        changeHandler: (e) =>
                                            setDescription(e.target.value),
                                    }}
                                />
                            </div>
                            <div className="flex flex-col lg:flex-row items-center justify-start w-full p-4">
                                <Button
                                    {...{
                                        type: 'submit',
                                        className: 'inline-block',
                                        color: 'primary',
                                    }}
                                >
                                    Submit
                                </Button>
                                {status === statuses.FAILED ? (
                                    <div className="flex items-center justify-center lg:justify-start w-full lg:w-auto py-3 px-4 mt-4 lg:mt-0 lg:ml-4 border border-primary border-dashed text-white text-lg text-center">
                                        Something went wrong, please contact us
                                        directly or try again.
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </form>
                ) : null}

                {status === statuses.SUCCESS ? (
                    <div className="flex items-center justify-center lg:justify-start w-full lg:w-auto py-3 px-4 mt-8 border border-primary border-dashed text-white text-lg text-center">
                        Thank you for your enquiry, we will be in touch as soon
                        as possible.
                    </div>
                ) : null}
            </div>
        </div>
    )
}
