import { getImage } from "gatsby-plugin-image";
import React from "react";
import Title from "@components/Title";
import BackgroundImage from "gatsby-background-image";
import { convertToBgImage } from "gbimage-bridge";
import Link from "@components/Link";
import SocialIcons from "@components/SocialIcons";
import LinkWithIcon from "@components/LinkWithIcon";

export default function Hero({
  blade: {
    heroBackground,
    heroTextTop,
    heroTextBottom,
    addText,
    addButton,
    heroButtonText,
    heroButtonUrl,
    enableAvailablePlatforms,
    platforms,
    socials,
    dateAvailable,
  },
}) {
  const background = getImage(heroBackground?.gatsbyImageData);
  const hero = convertToBgImage(background);
  return (
    <BackgroundImage
      Tag="section"
      {...hero}
      className="flex items-start justify-center py-36 lg:py-48 flex-col text-left bg-blend-darken"
    >
      <div className="w-full h-full absolute top-0 left-0 opacity-30 bg-black" />
      <div className="w-11/12 mx-auto max-w-screen-xl z-20 pb-6">
        <Title
          {...{
            type: 1,
            size: "7xl",
            color: "white",
            className: "mb-0",
          }}
        >
          {heroTextTop}
        </Title>
        <Title
          {...{
            type: 2,
            size: "5xl",
            color: "white",
            className: "mt-0",
          }}
        >
          {heroTextBottom}
        </Title>
        <div className="flex w-full md:w-fit">
          {addButton && (
            <Link
              {...{
                to: heroButtonUrl,
                external: true,
                target: "_blank",
                className: "w-full",
                type: "button",
                color: "black",
              }}
            >
              {heroButtonText}
            </Link>
          )}
        </div>
      </div>
      {enableAvailablePlatforms && (
        <div className="mx-auto text-white flex justify-between items-center w-full z-20 py-6 text-center absolute bottom-0 left-0">
          <div className="flex flex-col lg:flex-row gap-6 lg:gap-10 items-center justify-between relative w-11/12 mx-auto max-w-screen-xl">
            <div className="flex flex-row gap-4 items-center justify-center">
              {dateAvailable ? (
                <h3 className="font-body uppercase text-xl tracking-widest text-white">
                  {dateAvailable}
                </h3>
              ) : null}
              {platforms.map((platform, _index) => (
                <LinkWithIcon
                  {...{
                    key: _index,
                    to: platform.gamePlatformLink,
                    iconSrc: platform.platformLogo.url,
                    external: true,
                    className: "w-12",
                  }}
                />
              ))}
            </div>
            <SocialIcons
              {...{
                icons: socials,
                className:
                  "flex flex-row flex-nowrap items-center justify-center gap-6 top-0 !text-white",
              }}
            />
          </div>
        </div>
      )}
    </BackgroundImage>
  );
}

Hero.propTypes = {};
