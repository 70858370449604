import React from 'react';
import { getImage } from 'gatsby-plugin-image'
import FeaturesCard from '@components/FeaturesCard';
import Title from '@components/Title';
import Button from '@components/Button';
import { useWishlistState } from '@context';

export default function GameFeatures({ block: { gameFeatures, title, addButton, buttonText } }) {
    const { setShow } = useWishlistState();

    return (
        <div className="w-11/12 mx-auto pb-20 flex items-center justify-center flex-col">
            <Title {...{
                color: 'white',
                className: 'text-center text-5xl lg:text-6xl xl:text-6xl',
            }}>{title}</Title>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-12 gap-4 my-10 max-w-7xl mx-auto">
                {gameFeatures.map((feature, index) => {
                    const image = getImage(feature.image.gatsbyImageData);

                    return (
                        <FeaturesCard {...{
                            key: index,
                            image,
                            title: feature.title,
                            body: feature.body,
                            textColor: 'gray',
                            titleColor: 'white',
                            className: 'lg:col-span-3'
                        }} />
                    )
                })}
            </div>
            {addButton && (
                <div>
                    <Button {...{
                        color: 'primary',
                        className: '!text-3xl',
                        clickHandler: () => setShow(true),
                    }}>{buttonText}</Button>
                </div>
            )}
        </div>
    )
}
