import React from 'react'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'

import NewsCard from '@components/NewsCard'
import cx from 'classnames'
import getModelLink from '@utils/getModelLink'

export default function CarouselItem({
    type,
    data,
    index,
    locale,
    i,
    className,
    global,
}) {
    const resolveIcon = (name) => {
        name = name.toLowerCase()
        switch (name) {
            case 'guides':
                return global?.guideIcon?.url
            case 'creators':
                return global?.creatorsIcon?.url
            case 'news':
                return global?.newsIcon?.url
            default:
                break;
        }

        return name
    }

    switch (type) {
        case 'news':
        case 'guide':
            return (
                <NewsCard
                    {...{
                        title: data?.name ? data.name : data.newsTitle,
                        excerpt: data.excerpt,
                        slug: getModelLink(
                            data.pageSlug,
                            data.locale,
                            data?.model?.name
                        ),
                        locale,
                        meta: data.meta && data.meta,
                        heroImage: data?.cardSpecificImage ? data?.cardSpecificImage : data?.heroImage,
                        type: (data?.model?.name || type).toLowerCase(),
                        icon: resolveIcon(data?.model?.name || type),
                        className: `${index - 1 < i ? 'opacity-100' : 'opacity-0'
                            } transition-opacity duration-500`,
                    }}
                />
            )
        default:
            const image = getImage(data.img)
            return (
                <div
                    className={cx(
                        'structured-carousel__card col-span-2 xl:col-span-1 px-8 pr-2',
                        className
                    )}
                >
                    <GatsbyImage image={image} alt={data.img.alt} />
                </div>
            )
    }
}
