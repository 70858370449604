import React from "react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import ReactMarkdown from "react-markdown";
import Title from "@components/Title";
import Paragraph from "@components/Paragraph";
import Link from "@components/Link";
import Icon from "@components/Icon";

export default function PressKit({
  block: { title, body, assets, masterDownload, buttonText, footerText },
}) {
  return (
    <div className="w-11/12 max-w-7xl mx-auto relative text-center z-20">
      <Title
        {...{
          type: 2,
          color: "white",
          className: "mt-0 text-5xl lg:text-6xl xl:text-6xl",
        }}
      >
        {title}
      </Title>
      <Paragraph
        {...{
          className: "mt-0",
          color: "white",
        }}
      >
        {body}
      </Paragraph>

      <div className="flex flex-wrap -m-6 justify-center my-10 max-w-7xl mx-auto">
        {assets?.map(({ title, file, thumbnail, downloadText }, _index) => (
          <div className="w-full md:w-1/2 lg:w-1/4 p-6" key={_index}>
            <Link
              to={file?.url}
              download
              external={true}
              className="group flex flex-col w-full text-white lg:col-span-3 relative overflow-visible bg-white shadow-md"
            >
              <div className="transform group-hover:scale-110 xl:group-hover:scale-120 transition-all">
                <GatsbyImage
                  image={getImage(thumbnail)}
                  alt={thumbnail?.alt ? thumbnail.alt : `Thumbnail Image`}
                  className="w-full h-auto align-top"
                />
                <span className="block w-6 h-6 absolute top-0 right-0 bg-primary group-hover:scale-50 group-hover:bg-opacity-0 transition-all duration-300">
                  <span className="absolute bottom-1 left-1 w-6 h-6 overflow-hidden transform group-hover:-translate-x-full group-hover:translate-y-full transition-all group-hover:opacity-0 duration-500">
                    <span className="block w-6 h-6 bg-white absolute top-full right-full transform translate-x-1/2 -translate-y-1/2 rotate-45 origin-center" />
                  </span>
                </span>
              </div>

              <div className="flex flex-col justify-between items-center w-full h-full px-4 absolute top-0 left-0">
                {title ? (
                  <span className="my-6 font-display ru-countach-font font-bold text-black uppercase text-4xl lg:text-3xl transform group-hover:-translate-y-4 transition-all">
                    {title}
                  </span>
                ) : null}
                <span className="p-2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 origin-center rotate-90 group-hover:rotate-0 scale-75 group-hover:scale-100 border-2 border-dashed border-primary rounded-full transition-all opacity-0 group-hover:opacity-100 delay-100">
                  <span className="flex items-center justify-center w-20 h-20 text-center bg-black rounded-full">
                    <Icon
                      {...{
                        name: "download",
                        className:
                          "w-6 h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white",
                      }}
                    />
                  </span>
                </span>
                {downloadText ? (
                  <span className="my-6 font-display ru-countach-font font-bold text-white uppercase text-3xl lg:text-2xl transform group-hover:translate-y-4 transition-all">
                    {downloadText}
                  </span>
                ) : null}
              </div>
            </Link>
          </div>
        ))}
      </div>

      <div className="flex flex-col items-center justify-center">
        {masterDownload?.file?.url && buttonText ? (
          <Link
            {...{
              download: true,
              type: "button",
              external: true,
              to: masterDownload?.file?.url,
              className:
                "mt-8 bg-primary text-xl xl:text-2xl text-black text-center",
            }}
          >
            <div className="flex items-center justify-center">{buttonText}</div>
          </Link>
        ) : null}

        {footerText ? (
          <ReactMarkdown className="markdown max-w-2xl mt-12 text-white">
            {footerText}
          </ReactMarkdown>
        ) : null}
      </div>
    </div>
  );
}
