import React from 'react'
import Carousel from '@components/Carousel'
import cx from 'classnames'

export default function LatestNews({
    block: { __typename, body, title, posts: items },
    global,
    className,
    locale
}) {
    return (
        <div className={cx(className)}>
            <Carousel
                {...{
                    title,
                    body,
                    locale,
                    type: 'news',
                    items,
                    global,
                }}
            />
        </div>
    )
}
