import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

export default function Select({
    value,
    color = 'light',
    size = 'md',
    setValue,
    options,
    className,
}) {
    const selectRef = useRef(null)
    const [open, setOpen] = useState(false)

    useEffect(() => {
        function handleClickOutside(event) {
            if (selectRef?.current) {
                if (!selectRef?.current?.contains(event.target)) {
                    setOpen(false)
                } else {
                    setOpen(true)
                }
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [selectRef])

    const handleOptionSelect = (value) => {
        setValue(value)
        setOpen(false)
    }

    return (
        options.length > 0 && (
            <div
                ref={selectRef}
                className={`relative cursor-pointer border border-b-0 ${
                    open ? 'border-white' : 'border-transparent'
                }`}
            >
                <div
                    className={`border-b-2 py-3 font-body tracking-wide text-white ${
                        open ? 'text-opacity-50' : 'text-opacity-100'
                    } px-4 divide-x border-primary border-dashed bg-transparent w-full`}
                >
                    {options?.find((option) => value === option.value)?.label ||
                        options[0].label}
                </div>

                <div
                    className={`flex flex-col w-full max-h-[32rem] -mt-0.5 absolute top-full -left-px bg-black border border-white border-t-0 ${
                        open
                            ? 'opacity-100 visible pointer-events-auto'
                            : 'opacity-0 invisible pointer-events-none'
                    } overflow-y-scroll shadow-lg`}
                    style={{ width: 'calc(100% + 2px)' }}
                >
                    {options.map((option, i) => (
                        <div
                            key={i}
                            onClick={() => handleOptionSelect(option.value)}
                            className={`flex items-center justify-start h-14 px-4 truncate cursor-pointer ${
                                value === option.value
                                    ? 'bg-white bg-opacity-100 text-black'
                                    : 'hover:bg-white hover:bg-opacity-10'
                            }`}
                        >
                            <span>{option?.label}</span>
                        </div>
                    ))}
                </div>
            </div>
        )
    )
}

Select.propTypes = {
    value: PropTypes.string.isRequired,
    color: PropTypes.string,
    size: PropTypes.string,
    setValue: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.string.isRequired),
    className: PropTypes.string,
}

Select.defaultProps = {
    color: 'light',
    size: 'md',
}
