import { useStaticQuery, graphql } from "gatsby";
import React, { useEffect, useState } from "react";

import { StaticImage } from "gatsby-plugin-image";
import Title from "@components/Title";
import Button from "@components/Button";
import GuideListingCard from "@components/GuideListingCard";

const GuidesListing = ({
  block: { title, numberOfPostsToShow },
  locale,
  global,
}) => {
  const { guides } = useStaticQuery(graphql`
    query AllGuides {
      guides: allDatoCmsGuide(
        sort: { fields: meta___firstPublishedAt, order: DESC }
      ) {
        nodes {
          id
          locale
          locations
          pageSlug
          newsTitle
          excerpt
          guideCategory: category {
            categoryName
            locale
          }
          meta {
            firstPublishedAt(formatString: "DD/MM/YYYY")
          }
          heroImage {
            gatsbyImageData(placeholder: BLURRED)
            alt
          }
        }
      }
    }
  `);

  const [category, setCategory] = useState("All");
  const [show, setShow] = useState();

  useEffect(() => {
    setShow(numberOfPostsToShow);
  }, [numberOfPostsToShow]);

  const sortedGuides = guides?.nodes?.filter(
    ({ locale: _locale }) => _locale === locale
  );

  const filteredGuides = sortedGuides?.filter(
    ({ guideCategory: _category }) =>
      category === "All" || category === _category?.categoryName
  );

  const categories = [
    "All",
    ...sortedGuides
      ?.reduce((categories, { guideCategory: category }) => {
        return categories.indexOf(category?.categoryName) === -1
          ? [...categories, category?.categoryName]
          : categories;
      }, [])
      ?.filter((c) => c?.length > 0),
  ];

  return (
    <div className="w-11/12 mx-auto max-w-7xl relative z-20">
      <div className="flex flex-col md:flex-row items-center justify-center md:justify-between">
        <Title
          {...{
            type: 2,
            color: "black",
            className: "mt-0 text-5xl lg:text-6xl xl:text-6xl leading-none",
          }}
        >
          {title}
        </Title>

        {categories.length ? (
          <div className="flex md:inline-flex items-center justify-center md:justify-end w-full md:w-auto bg-grey mt-4 md:mt-0 relative bg-grey overflow-hidden">
            <StaticImage
              src="../../assets/geolines/bkg-black-lines-left@2x.png"
              className="!absolute min-w-[450px] top-0 left-0 !pointer-events-none"
              alt="Geolines Graphic"
            />

            {categories?.map((_category, _index) => (
              <button
                key={_index}
                type="button"
                onClick={() => setCategory(_category)}
                className={`inline-flex items-center justify-center h-16 px-8 font-display ru-countach-font uppercase text-xl xl:text-2xl text-white ${
                  category === _category
                    ? "text-opacity-100"
                    : "text-opacity-50 hover:text-opacity-80"
                } relative transition-all`}
              >
                <div className="flex flex-col items-center justify-center relative">
                  {_category}
                  <span
                    className={`block ${
                      category === _category ? "w-full" : "w-0"
                    } h-1 bg-primary transition-all duration-300`}
                  />
                </div>
              </button>
            ))}
          </div>
        ) : null}
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-4 sm:gap-x-8 md:gap-x-16 mb-10 mt-10">
        {[].concat(sortedGuides).map((guide, i) => (
          <GuideListingCard
            {...{
              key: i,
              hide:
                (i >= show && category === "") ||
                (filteredGuides.find(({ id }) => id === guide?.id) || [])
                  .length === 0,
              ...guide,
              icon: global?.guideIcon?.url,
              locale,
            }}
          />
        ))}
      </div>

      {show < sortedGuides.length &&
      filteredGuides.length === sortedGuides.length ? (
        <div className="flex items-center justify-center">
          <Button
            {...{
              className: "mx-auto block",
              color: "primary",
              clickHandler: () => setShow(show + 8),
            }}
          >
            Load More
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default GuidesListing;
