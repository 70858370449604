import React from "react";
import cx from "classnames";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import Svg from "react-inlinesvg";

import Title from "@components/Title";
import Link from "@components/Link";

import { motion } from "framer-motion";

import { Link as GatsbyLink } from "gatsby";

import { isToday, isYesterday } from "@utils/dates";
import getModelLink from "@utils/getModelLink";
import { GetLocaleButtons } from "@staticQueries/localeButtons";

export default function NewsListingCard({
  pageSlug,
  newsTitle,
  heroImage,
  category,
  meta,
  animate,
  className,
  locale,
  hide = false,
  icon,
}) {
  const {
    config: {
      node: { newsButtonText },
    },
  } = GetLocaleButtons(locale);

  const image = getImage(heroImage.gatsbyImageData);
  const date = new Date(meta.firstPublishedAt);
  const resolvedDate = isToday(date)
    ? "Today"
    : isYesterday(date)
    ? "Yesterday"
    : meta.firstPublishedAt;

  return (
    <motion.div
      variants={animate}
      className={cx(`flex flex-col items-start group relative`, className, {
        hidden: hide,
      })}
    >
      <GatsbyLink to={getModelLink(pageSlug, locale, "news")}>
        <div className="overflow-hidden">
          <GatsbyImage
            image={image}
            alt={heroImage?.alt ? heroImage.alt : `Hero Image`}
            className="group-hover:scale-105 transition-transform duration-500"
          />
        </div>
        <div className="my-4">
          <div className="uppercase font-bold flex flex-row flex-nowrap gap-2 text-sm">
            <span>{resolvedDate}</span> | <span>{category?.categoryName}</span>
          </div>
          <Title
            {...{
              type: 3,
              font: "display",
              className: "my-3 text-3xl md:text-3xl lg:text-4xl xl:text-4xl",
            }}
          >
            {newsTitle}
          </Title>
          <div className="w-fit">
            <p
              {...{
                to: getModelLink(pageSlug, locale, "news"),
                className:
                  "uppercase text-primary !font-light font-display ru-countach-font tracking-wider text-xl",
              }}
            >
              {newsButtonText}
            </p>
            <span className="block w-full h-0.5 mt-1 bg-primary" />
          </div>
        </div>
        {icon ? (
          <div className="clip-tag w-12 h-12 bg-primary absolute top-0 right-0">
            <Svg
              src={icon}
              className="w-3 h-auto absolute top-2 right-2 text-black"
            />
          </div>
        ) : null}
      </GatsbyLink>
    </motion.div>
  );
}
