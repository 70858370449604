import React, { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

import { useLightboxState } from "@context";

import Paragraph from "@components/Paragraph";
import AboutCard from "@components/AboutCard";
import WatchTrailerIcon from "@components/WatchTrailerIcon";

import "./styles.scss";
import { useCurrentWidth } from "@hooks/useCurrentWidth";

export default function AboutReel({ block }) {
  const [index, setIndex] = useState(0);
  const [direction, setDirection] = useState(1);
  /* eslint-disable no-unused-vars */
  const [active, setActive] = useState(true);
  const [paused, setPaused] = useState(false);
  const currentWidth = useCurrentWidth();

  const {
    setLightboxImages,
    setLightboxVideo,
    setLightboxOpen,
    setActiveLightboxImage,
  } = useLightboxState();

  //convert reels from 4 gridItems to 2 gridItems each whilst preserving all other info for mobile
  const mobBlock = {
    ...block,
    reels: block.reels
      .map((reel) => [
        { ...reel, gridItems: [reel.gridItems[0], reel.gridItems[1]] },
        { ...reel, gridItems: [reel.gridItems[2], reel.gridItems[3]] },
      ])
      .flat(),
  };

  const _block = currentWidth < 1024 ? mobBlock : block;

  const handleLightbox = (asset, isVideo) => {
    setActiveLightboxImage(0);
    isVideo ? setLightboxVideo(asset) : setLightboxImages([asset]);
    setLightboxOpen(true);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(index + 1),
    onSwipedRight: () => updateIndex(index - 1),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const updateIndex = (newIndex) => {
    if (window.innerWidth < 1024 && newIndex >= _block.reels.length)
      newIndex = 0;
    if (newIndex < 0) newIndex = _block.reels.length;
    else if (newIndex >= _block.reels.length + 1) newIndex = 0;
    setIndex(newIndex);
  };

  const navigate = ({ forceDirection = null }) => {
    const offset =
      window.innerWidth > 768 ? (window.innerWidth > 1280 ? 1 : 1) : 1;
    const isDesktop = window.innerWidth > 1024 ? 1 : 0;

    if (!forceDirection) {
      if (index === 1 && direction === 0) {
        setDirection(1);
      } else if (index === _block.reels.length - offset && direction === 1) {
        setDirection(0);
      }
    }

    if (forceDirection === 0 || (forceDirection === null && direction === 0)) {
      setIndex(Math.max(0, index - 1));
    }
    if (forceDirection === 1 || (forceDirection === null && direction === 1)) {
      if (_block.reels.length === index) {
        setIndex(0);
      } else {
        setIndex(Math.min(_block.reels.length - (offset - 1), index + 1));
      }
    }
  };

  const handlePrevClick = (e) => {
    e.persist();
    setActive(false);
    navigate({ forceDirection: 0 });
    setTimeout(() => {
      setActive(true);
    }, 5000);
  };

  const handleNextClick = (e) => {
    e.persist();
    setActive(false);
    navigate({ forceDirection: 1 });
    setTimeout(() => {
      setActive(true);
    }, 5000);
  };

  const dummySlide = (
    <div className="about-carousel__card col-span-2 md:col-span-3 lg:col-span-2 xl:col-span-3 lg:px-8 text-left relative flex flex-col xs:grid  gap-6 lg:gap-x-12 lg:gap-y-6 max-w-[90vw] lg:max-w-none justify-center items-center">
      <span className="pointer-events-none opacity-0">dummy</span>
    </div>
  );

  return (
    <div
      {...{
        className:
          "about-carousel mx-auto max-w-screen-xl cursor-pointer pb-10 w-11/12",
        onMouseEnter: () => setPaused(true),
        onMouseLeave: () => setPaused(false),
      }}
      {...handlers}
    >
      <div className="relative z-30">
        <div className="lg:grid relative about-carousel__wrapper lg:grid-cols-aboutReel lg:grid-flow-col lg:gap-10">
          <div
            className={`${
              index > 0 ? "lg:opacity-0" : "lg:opacity-100"
            } transition-opacity duration-300 col-span-2 lg:col-span-1 flex justify-between flex-col lg:py-8 lg:px-2`}
          >
            <Paragraph
              {...{
                color: "white",
              }}
            >
              {_block.body}
            </Paragraph>

            <WatchTrailerIcon
              {...{
                video: _block.internalOrExternalVideo
                  ? _block.externalVideo.url
                  : _block.video.video,
                thumbnail: _block.internalOrExternalVideo
                  ? _block.externalVideo.thumbnailUrl
                  : _block.video.thumbnailUrl,
                icon: "play",
                className: "text-white my-8 md:my-20",
                clickHandler: () =>
                  handleLightbox(
                    _block.internalOrExternalVideo
                      ? _block.externalVideo
                      : _block.video.video,
                    true
                  ),
                headerText: _block.watchTrailerText,
              }}
            />
          </div>
          <div className="flex flex-row flex-nowrap lg:col-span-2">
            <div
              className={`about-carousel__slider grid grid-cols-1 grid-flow-col overflow-x-visible transition-all duration-500 gap-4 slide-${index} w-full`}
            >
              {_block.reels.map((reel, i) => {
                return (
                  <AboutCard
                    {...{
                      key: i,
                      className: `${
                        i + 1 < index ? "opacity-0" : "opacity-100"
                      } w-full transition-opacity duration-500 flex justify-center items-center max-w-[90vw] lg:max-w-none`,
                      ...reel,
                    }}
                  />
                );
              })}
              {(() => {
                const slides = [];
                for (let i = 8; i > _block.reels.length; i--) {
                  slides.push(dummySlide);
                }

                return slides;
              })()}
            </div>
          </div>
        </div>

        <div className="flex flex-row mt-6 gap-4">
          {index > 0 && (
            <div
              onClick={handlePrevClick}
              className="w-12 lg:w-16 h-12 lg:h-16 rounded-full bg-white flex items-center justify-center relative cursor-pointer group"
            >
              <AiOutlineLeft className="text-black w-6 lg:w-8 h-6 lg:h-8" />
              <span className="w-[120%] h-[120%] border border-primary border-dashed absolute rounded-full group-hover:opacity-100 transition-all opacity-0" />
            </div>
          )}
          {index < _block.reels.length * 2 && (
            <div
              onClick={handleNextClick}
              className="w-12 lg:w-16 h-12 lg:h-16 rounded-full bg-white flex items-center justify-center relative cursor-pointer group"
            >
              <AiOutlineRight className="text-black w-6 lg:w-8 h-6 lg:h-8" />
              <span className="w-[120%] h-[120%] border border-primary border-dashed absolute rounded-full group-hover:opacity-100 transition-all opacity-0" />
            </div>
          )}
        </div>

        <div className="flex flex-row flex-nowrap mt-8 rounded-full overflow-hidden w-fit bg-white">
          {_block.reels &&
            _block.reels.map((reel, i) => (
              <span
                key={i}
                className={`${
                  i === index ? `bg-primary rounded-full` : "bg-offwhite"
                } w-6 h-1 block transition-colors`}
              />
            ))}
        </div>
      </div>
    </div>
  );
}
