import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

export default function Text({
    label,
    name,
    value,
    placeholder,
    type = 'text',
    placeholderClass,
    disabled = false,
    required = false,
    className,
    changeHandler,
}) {
    return (
        <div className="w-full">
            {label && <span>{label}</span>}
            <input
                {...{
                    type,
                    name,
                    value,
                    placeholder,
                    disabled,
                    required,
                    className: cx(
                        'border-b-2 py-3 font-body tracking-wide text-white px-4 divide-x border-primary border-dashed bg-transparent w-full',
                        placeholderClass,
                        { disabled }
                    ),
                    onChange: (e) => changeHandler(e),
                }}
            />
        </div>
    )
}

Text.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    changeHandler: PropTypes.func,
}

Text.defaultProps = {
    disabled: false,
    required: false,
}
