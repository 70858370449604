import Title from '@components/Title'
import Paragraph from '@components/Paragraph'
import BackgroundImage from 'gatsby-background-image'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import React, { useEffect, useState } from 'react'
import Text from '@components/Inputs/Text'
import Checkbox from '@components/Inputs/Checkbox'
import Button from '@components/Button'

// import validateEmail from '../../utils/validateEmail';

import addToMailchimp from 'gatsby-plugin-mailchimp'

export default function Newsletter({
    block: { __typename, backgroundImage, body, id, title, firstNamePlaceholder, lastNamePlaceholder, emailPlaceholder, consentLabel, submitButtonText },
}) {
    const image = getImage(backgroundImage.gatsbyImageData)
    const bg = convertToBgImage(image)

    const [progress, setProgress] = useState('none')
    const [feedback, setFeedback] = useState('')

    const [firstName, setFirstName] = useState('')
    const [validFirstName, setValidFirstName] = useState(false)
    const [lastName, setLastName] = useState('')
    const [validLastName, setValidLastName] = useState(false)
    const [email, setEmail] = useState('')
    const [validEmail, setValidEmail] = useState(false)
    const [consent, setConsent] = useState(false)
    const [validConsent, setValidConsent] = useState(false)

    const validateEmail = (email) => {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(email)
    }

    useEffect(() => {
        setValidFirstName(progress === 'none' || firstName)
        setValidLastName(progress === 'none' || lastName)
        setValidEmail(progress === 'none' || validateEmail(email))
        setValidConsent(progress === 'none' || consent)
    }, [firstName, lastName, consent, email, progress])

    const handleSubmit = (e) => {
        e.preventDefault()
        setProgress('pending')

        setValidFirstName(progress === 'none' || firstName)
        setValidLastName(progress === 'none' || lastName)
        setValidEmail(progress === 'none' || validateEmail(email))
        setValidConsent(progress === 'none' || consent)

        if (validFirstName && validLastName && validEmail && validConsent) {
            addToMailchimp(email, {
                EMAIL: email,
                FNAME: firstName,
                LNAME: lastName,
            })
                .then((data) => {
                    const result = data?.result

                    if (data?.msg) {
                        setFeedback(data.msg)
                    }

                    if (
                        result === 'error' &&
                        !data?.msg?.includes('already subscribed')
                    ) {
                        setProgress('error')
                    } else if (
                        result === 'success' ||
                        data?.msg?.includes('already subscribed')
                    ) {
                        setProgress('success')
                    }
                })
                .catch((e) => {
                    setProgress('error')
                })
        }
    }

    return (
        <div className="w-11/12 mx-auto">
            <div className="grid grid-cols-1 lg:grid-cols-12 bg-black p-4 gap-6 lg:max-w-7xl mx-auto shadow-md relative z-20">
                <BackgroundImage
                    Tag="div"
                    {...bg}
                    className="bg-opacity-50 w-full col-span-full lg:col-span-5"
                >
                    <div className="py-8 px-10 xl:px-14 xl:py-10 bg-black bg-opacity-50 h-full">
                        <Title
                            {...{
                                type: 1,
                                size: '6xl',
                                color: 'white',
                                className:
                                    'mb-8 mt-4 text-3xl md:text-4xl lg:text-5xl xl:text-6xl',
                            }}
                        >
                            {title}
                        </Title>
                        <Paragraph
                            {...{
                                color: 'white',
                                className: 'max-w-[300px] lg:text-lg',
                            }}
                        >
                            {body}
                        </Paragraph>
                    </div>
                </BackgroundImage>
                <form
                    className="text-grey lg:col-span-7 lg:py-16"
                    onSubmit={handleSubmit}
                >
                    {progress !== 'success' && (
                        <>
                            <div className="w-11/12 mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 mb-10 lg:mb-16">
                                <Text
                                    {...{
                                        name: 'first_name',
                                        placeholder: firstNamePlaceholder,
                                        required: true,
                                        changeHandler: (e) =>
                                            setFirstName(e.target.value),
                                    }}
                                />
                                <Text
                                    {...{
                                        name: 'last_name',
                                        placeholder: lastNamePlaceholder,
                                        required: true,
                                        changeHandler: (e) =>
                                            setLastName(e.target.value),
                                    }}
                                />
                                <Text
                                    {...{
                                        name: 'email_address',
                                        placeholder: emailPlaceholder,
                                        type: 'email',
                                        required: true,
                                        changeHandler: (e) =>
                                            setEmail(e.target.value),
                                    }}
                                />
                                <Checkbox
                                    {...{
                                        name: 'consent',
                                        label: consentLabel,
                                        checked: consent,
                                        changeHandler: () =>
                                            setConsent(!consent),
                                        className: 'text-light',
                                    }}
                                />
                            </div>
                            <Button
                                {...{
                                    clickHandler: () => true,
                                    type: 'submit',
                                    color: 'white',
                                    className:
                                        'w-fit lg:flex text-center mx-auto items-center justify-center text-xl xl:text-2xl',
                                }}
                            >
                                {submitButtonText}
                            </Button>
                        </>
                    )}
                    {feedback && (
                        <div className="mb-10 lg:mb-16 w-11/12 mx-auto leading-6 lg:leading-8 text-md md:text-lg lg:text-xl text-white font-body tracking-wide">
                            <p dangerouslySetInnerHTML={{ __html: feedback }} />
                        </div>
                    )}
                </form>
            </div>
        </div>
    )
}
