import React, { useEffect, useState } from "react";

import cx from "classnames";
import { useSwipeable } from "react-swipeable";

import Title from "@components/Title";
import Paragraph from "@components/Paragraph";
import Link from "@components/Link";

import "./styles.scss";

const LatestSocial = ({ block: { title, subtext }, className, locale }) => {
  /* eslint-disable no-unused-vars */
  const [index, setIndex] = useState(0);
  const [direction, setDirection] = useState(1);
  const [active, setActive] = useState(true);
  const [paused, setPaused] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) updateIndex(index + 1);
    }, 10000);

    return () => {
      if (interval) clearInterval(interval);
    };
  });

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(index + 1),
    onSwipedRight: () => updateIndex(index - 1),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const updateIndex = (newIndex) => {
    // if (newIndex < 0) newIndex = items.length - 1;
    // else if (newIndex >= items.length) newIndex = 0;
    // setIndex(newIndex);
  };

  const navigate = ({ forceDirection = null }) => {
    const offset =
      window.innerWidth > 768 ? (window.innerWidth > 1280 ? 2 : 2) : 1;

    // if (!forceDirection) {
    //     if (index === 1 && direction === 0) {
    //         setDirection(1)
    //     } else if (index === (items.length - offset) && direction === 1) {
    //         setDirection(0)
    //     }
    // }

    // if (forceDirection === 0 || ((forceDirection === null) && direction === 0)) setIndex(Math.max(0, index - 1))
    // if (forceDirection === 1 || ((forceDirection === null) && direction === 1)) setIndex(Math.min(items.length - (offset - 1), index + 1))
  };

  const handlePrevClick = () => {
    setActive(false);
    navigate({ forceDirection: 0 });
    setTimeout(() => {
      setActive(true);
    }, 5000);
  };

  const handleNextClick = () => {
    setActive(false);
    navigate({ forceDirection: 1 });
    setTimeout(() => {
      setActive(true);
    }, 5000);
  };

  return (
    <div
      {...{
        className: cx(
          "social-carousel max-w-screen-xl w-11/12 mx-auto overflow-visible",
          className
        ),
        onMouseEnter: () => setPaused(true),
        onMouseLeave: () => setPaused(false),
      }}
      {...handlers}
    >
      <div className="relative z-20">
        <div className="grid grid-cols-12 relative">
          <div className="col-span-full lg:col-span-3">
            <Title
              {...{
                className: "",
                type: 2,
                size: "6xl",
              }}
            >
              {title}
            </Title>

            <div className="flex flex-col gap-4">
              <Title
                {...{
                  className:
                    "font-bolder text-2xl md:text-2xl lg:text-2xl xl:text-2xl mb-0",
                  type: 3,
                  font: "body",
                }}
              >
                {subtext}
              </Title>

              <div className="w-fit">
                <Link
                  {...{
                    to: "https://twitter.com",
                    external: true,
                    className:
                      "font-display ru-countach-font uppercase text-xl",
                  }}
                >
                  @Twitterhandle
                </Link>
                <span className="block bg-primary h-1 w-full" />
              </div>

              <div className="w-fit">
                <Link
                  {...{
                    to: "https://instagram.com",
                    external: true,
                    className:
                      "font-display ru-countach-font  ru-countach-font uppercase text-xl",
                  }}
                >
                  @Instagramhandle
                </Link>
                <span className="block bg-primary h-1 w-full" />
              </div>
            </div>
          </div>

          <div className="col-span-full lg:col-span-9 xl:col-span-13 flex flex-row flex-nowrap cursor-pointer">
            <div
              className={`social-carousel__slider grid grid-cols-1 gap-2 grid-flow-col overflow-x-visible mt-3 lg:mt-0 duration-500 transition slide-${index}`}
            >
              <div className="col-span-2 xl:col-span-1 p-4 bg-black relative mr-2">
                <img src="https://via.placeholder.com/1920" alt="placeholder" />
                <div className="mt-4 px-4">
                  <div className="text-offwhite flex flex-row flex-nowrap gap-4 text-lg">
                    <span className="">TODAY</span>|
                    <Link
                      {...{
                        className:
                          "text-primary font-display ru-countach-font uppercase text-xl",
                      }}
                    >
                      @Twitterhandle
                    </Link>
                  </div>
                  <Paragraph
                    {...{
                      className: "!leading-6 mt-2",
                    }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, in
                    turpis libero, fringila vitea dui vel, omara blandif massa.
                    Deonec id fellus ut nunc ullamcorper #arnare #trinilla
                  </Paragraph>
                </div>

                <div className="w-full h-full border-2 border-dashed border-grey absolute -top-2 left-2 z-20" />
              </div>

              <div className="col-span-2 xl:col-span-1 p-4 bg-black relative mr-2">
                <img src="https://via.placeholder.com/1920" alt="placeholder" />
                <div className="mt-4 px-4">
                  <div className="text-offwhite flex flex-row flex-nowrap gap-4 text-lg">
                    <span className="">TODAY</span>|
                    <Link
                      {...{
                        className:
                          "text-primary font-display ru-countach-font uppercase text-xl",
                      }}
                    >
                      @Twitterhandle
                    </Link>
                  </div>
                  <Paragraph
                    {...{
                      className: "!leading-6 mt-2",
                    }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, in
                    turpis libero, fringila vitea dui vel, omara blandif massa.
                    Deonec id fellus ut nunc ullamcorper #arnare #trinilla
                  </Paragraph>
                </div>

                <div className="w-full h-full border-2 border-dashed border-grey absolute -top-2 left-2 z-20" />
              </div>

              <div className="col-span-2 xl:col-span-1 p-4 bg-black relative mr-2">
                <img src="https://via.placeholder.com/1920" alt="placeholder" />
                <div className="mt-4 px-4">
                  <div className="text-offwhite flex flex-row flex-nowrap gap-4 text-lg">
                    <span className="">TODAY</span>|
                    <Link
                      {...{
                        className:
                          "text-primary font-display ru-countach-font uppercase text-xl",
                      }}
                    >
                      @Twitterhandle
                    </Link>
                  </div>
                  <Paragraph
                    {...{
                      className: "!leading-6 mt-2",
                    }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, in
                    turpis libero, fringila vitea dui vel, omara blandif massa.
                    Deonec id fellus ut nunc ullamcorper #arnare #trinilla
                  </Paragraph>
                </div>

                <div className="w-full h-full border-2 border-dashed border-grey absolute -top-2 left-2 z-20" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatestSocial;
