import React, { useState } from "react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

import { useLightboxState } from "@context";

import Title from "@components/Title";
import Geolines from "@components/Geolines";
import Button from "@components/Button";

const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export default function ImageGrid({
  block: { title, assets, loadMoreButtonText },
}) {
  const { setLightboxImages, setLightboxOpen, setActiveLightboxImage } =
    useLightboxState();
  const [showFirst, setShowFirst] = useState(10);
  const [tag, setTag] = useState("All");

  const tags = [
    "All",
    ...assets?.map(({ primaryCategoryName }) => primaryCategoryName),
  ];

  const _assets = assets?.reduce(
    (assets, { primaryCategoryName, subcategories }) => {
      let images = [];

      if (primaryCategoryName === tag || tag === "All" ? subcategories : null) {
        subcategories?.map(({ images: _images }) => {
          return (images = [...images, ..._images]);
        });
      }

      return [...assets, ...images];
    },
    []
  );

  const handleImageClick = (index) => {
    setLightboxImages(_assets);
    setActiveLightboxImage(index);
    setLightboxOpen(true);
  };

  return (
    <div className="w-11/12 max-w-7xl mx-auto relative z-20">
      <div className="flex flex-col md:flex-row items-center justify-center md:justify-between">
        <Title
          {...{
            type: 2,
            color: "black",
            className: "m-0 text-5xl lg:text-6xl xl:text-6xl leading-none",
          }}
        >
          {title}
        </Title>

        {tags.length ? (
          <div className="flex md:inline-flex items-center justify-center md:justify-end w-full md:w-auto bg-grey mt-4 md:mt-0 relative bg-grey overflow-hidden">
            <Geolines
              {...{
                name: "geolines1",
                className:
                  "!absolute top-1/2 left-1/2 transform -translate-x-2/3 -translate-y-1/2 max-w-[750px] 2xl:max-w-[950px] z-10 opacity-75 !pointer-events-none",
              }}
            />

            {tags?.map((_tag, _index) => (
              <button
                key={_index}
                type="button"
                onClick={() => setTag(_tag)}
                className={`inline-flex items-center justify-center h-16 px-8 font-display ru-countach-font uppercase text-xl xl:text-2xl text-white ${
                  tag === _tag
                    ? "text-opacity-100"
                    : "text-opacity-50 hover:text-opacity-80"
                } relative transition-all`}
              >
                <div className="flex flex-col items-center justify-center relative">
                  {capitalize(_tag)}
                  <span
                    className={`block ${
                      tag === _tag ? "w-full" : "w-0"
                    } h-1 bg-primary transition-all duration-300`}
                  />
                </div>
              </button>
            ))}
          </div>
        ) : null}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-12 gap-2 my-10 max-w-7xl mx-auto">
        {_assets?.map((image, i) => {
          const _big = [8, 1].indexOf(Number(String(i + 1).slice(-1))) >= 0;

          return (
            <div
              key={i}
              className={`${i < showFirst ? "block" : "hidden"} ${
                _big
                  ? "col-span-1 lg:col-span-6 lg:row-span-2 cursor-pointer"
                  : "col-span-1 lg:col-span-3 cursor-pointer"
              } w-full h-full group bg-white shadow-md flex flex-col mx-auto flex-nowrap text-white relative overflow-visible`}
              onClick={() => (_big ? handleImageClick(i) : handleImageClick(i))}
            >
              <div className="pt-[60%]">
                <div className="w-full h-full absolute top-0 left-0">
                  <GatsbyImage
                    image={getImage(image)}
                    alt={image.alt ? image.alt : `alt`}
                    className="w-full h-full absolute top-0 left-0"
                    objectPosition="bottom left"
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {showFirst < _assets?.length ? (
        <div className="flex items-center justify-center">
          <Button
            {...{
              clickHandler: () => setShowFirst(showFirst + 10),
              color: "primary",
              className:
                "mt-8 bg-primary text-xl xl:text-2xl text-black text-center",
            }}
          >
            {loadMoreButtonText}
          </Button>
        </div>
      ) : null}
    </div>
  );
}
