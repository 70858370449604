import { graphql, useStaticQuery } from "gatsby";
import React from "react";

import { getImage, GatsbyImage } from "gatsby-plugin-image";
import Title from "@components/Title";
import Paragraph from "@components/Paragraph";
import Link from "@components/Link";
import getModelLink from "@utils/getModelLink";
import { GetLatestSingleNews } from "@staticQueries/latestNews";
import { GetLocaleButtons } from "@staticQueries/localeButtons";

const SingleNews = ({
  block: { defaultBehaviour = true, title, newsPost },
  locale = "en",
}) => {
  const post = defaultBehaviour ? GetLatestSingleNews() : newsPost;
  const {
    config: {
      node: { newsButtonText },
    },
  } = GetLocaleButtons(locale);

  const image = getImage(post.heroImage.gatsbyImageData);

  return (
    <div className="mx-auto max-w-screen-xl w-11/12 z-20 relative">
      {title && (
        <Title
          {...{
            type: 1,
            size: "6xl",
            color: "white",
            className:
              "mt-2 pointer-events-none mx-auto text-center xl:text-6xl lg:text-6xl md:text-5xl text-4xl",
          }}
        >
          {title}
        </Title>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 mt-12">
        {post && (
          <>
            <GatsbyImage
              image={image}
              objectFit="cover"
              objectPosition="center"
              alt={
                post?.heroImage?.alt ? post.heroImage.alt : `Post Hero Image`
              }
            />
            <div className="p-10 pt-10 pb-0 lg:pb-10 text-white lg:px-20 xl:px-28">
              <span className="uppercase text-light font-display ru-countach-font text-xl opacity-80 tracking-widest">
                {post.meta.firstPublishedAt}
              </span>
              <Title
                {...{
                  type: 2,
                  size: "4xl",
                  color: "white",
                  className:
                    "my-4 xl:text-4xl lg:text-4xl md:text-3xl text-2xl",
                }}
              >
                {post.newsTitle}
              </Title>
              <Paragraph
                {...{
                  color: "white",
                }}
              >
                {post.excerpt}
              </Paragraph>

              <Link
                {...{
                  type: "button",
                  to: getModelLink(post.pageSlug, post.locale, "news"),
                  className: "block mt-8 w-fit",
                  color: "primary",
                }}
              >
                {newsButtonText}
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SingleNews;
