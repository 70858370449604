import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'

import Title from '@components/Title'
import Paragraph from '@components/Paragraph'
import Link from '@components/Link'
import Icon from '@components/Icon'

import './style.scss'

const FaqCard = ({ question, answer, active: _active }) => {
    const [active, setActive] = useState(_active)

    return (
        <div
            className="py-8 border-b border-offwhite cursor-pointer"
            onClick={() => setActive(!active)}
        >
            <div className="flex items-start justify-between">
                <div
                    className={`flex-0 block p-1 border border-dashed border-primary mr-8 transform ${active ? 'rotate-180' : 'rotate-0'
                        } relative rounded-full transition-all`}
                >
                    <div className="w-14 h-14 bg-white relative rounded-full">
                        <Icon
                            name="arrowDown"
                            className="w-4 h-auto absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white"
                        />
                    </div>
                </div>

                <div className="flex-1">
                    <h4 className="mt-4 text-xl font-bold text-white !leading-none">
                        {question}
                    </h4>
                    <ReactMarkdown
                        className={`${active ? 'block' : 'hidden'
                            } mt-4 text-white`}
                    >
                        {answer}
                    </ReactMarkdown>
                </div>
            </div>
        </div>
    )
}

export default function Faqs({
    block: {
        title,
        faqs,
        subheading,
        body,
        buttonLink,
        buttonText,
        bottomText,
    },
}) {
    return (
        <div className="w-11/12 max-w-4xl mx-auto relative z-20">
            <div className="flex flex-col items-center justify-center text-center">
                {title ? (
                    <Title
                        {...{
                            type: 2,
                            color: 'white',
                            className:
                                'm-0 text-5xl lg:text-6xl xl:text-6xl leading-none',
                        }}
                    >
                        {title}
                    </Title>
                ) : null}

                {body ? (
                    <Paragraph
                        {...{
                            color: 'white',
                            className: `max-w-lg mx-auto lg:mx-0 ${title?.length ? 'mt-8' : 'mt-0'
                                } !leading-tight pointer-events-none text-opacity-50`,
                        }}
                    >
                        {body}
                    </Paragraph>
                ) : null}
            </div>

            <div className="flex items-center justify-between mb-4 md:mb-8 mt-12 md:mt-20 text-left">
                {subheading ? (
                    <Title
                        {...{
                            type: 2,
                            color: 'primary',
                            className: 'm-0 text-3xl xl:text-4xl leading-none',
                        }}
                    >
                        {subheading}
                    </Title>
                ) : null}

                {buttonLink && buttonText ? (
                    <div className="flex items-center justify-center lg:justify-start">
                        <Link
                            {...{
                                type: 'button',
                                external: true,
                                to: buttonLink,
                                className:
                                    'bg-primary text-xl xl:text-2xl text-black',
                            }}
                        >
                            {buttonText}
                        </Link>
                    </div>
                ) : null}
            </div>

            {faqs?.map((faq, i) => (
                <FaqCard {...{ key: i, ...faq, active: i === 0 }} />
            ))}

            {bottomText ? (
                <div className="faqs__bottom-text flex items-center justify-end mt-12 md:mt-20 text-right text-white">
                    <ReactMarkdown>{bottomText}</ReactMarkdown>
                </div>
            ) : null}
        </div>
    )
}
