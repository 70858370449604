import { graphql, useStaticQuery } from "gatsby"

export const GetLatestSingleNews = () => {
    const { post } = useStaticQuery(graphql`
        query LatestPost {
            post: allDatoCmsNews(
                limit: 1
                sort: {fields: meta___firstPublishedAt, order: DESC}
                filter: {locale: {eq: "en"}}
            ) {
                    nodes {
                        id
                        locale
                        pageSlug
                        newsTitle
                        excerpt
                        heroImage {
                            gatsbyImageData(placeholder: BLURRED)
                        }
                        meta {
                            firstPublishedAt(formatString: "DD/MM/YYYY")
                        }
                    }
                }
        }
    `)

    const result = post.nodes[0]

    return { ...result }
}