import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Link from '@components/Link'
import Button from '@components/Button'

import { useParallax } from 'react-scroll-parallax'

import { useInView } from 'react-intersection-observer'
import { useLogoState } from '@context'
import AvaliableOn from '@components/AvailableOn'
import { useWishlistState } from '@context'

import './styles.scss'

const ParallaxHero = ({ blade }) => {
    const { setLogoPosition } = useLogoState()
    const { setShow } = useWishlistState()

    const { ref, inView } = useInView({
        threshold: 0,
        rootMargin: '-100px',
    })

    useEffect(() => {
        setLogoPosition(inView)
    }, [inView, setLogoPosition])

    const { configs } = useStaticQuery(graphql`
        query StaticLogoQuery {
            configs: allDatoCmsAnglerGlobalSetting(
                filter: { locale: { eq: "en" } }
            ) {
                nodes {
                    id
                    locale
                    blackLogo {
                        gatsbyImageData(placeholder: BLURRED)
                        alt
                    }
                }
            }
        }
    `)

    const blackLogo = getImage(configs?.nodes[0]?.blackLogo?.gatsbyImageData)

    const assets = {
        one: getImage(blade.layerOne.gatsbyImageData),
        two: getImage(blade.layerTwo.gatsbyImageData),
        three: getImage(blade.layerThree.gatsbyImageData),
        four: getImage(blade.layerFour.gatsbyImageData),
        five: getImage(blade.layerFive.gatsbyImageData),
    }

    const refOne = useParallax({ speed: 25, translateY: 25 })
    const refTwo = useParallax({ speed: 15, translateY: 15 })
    const refThree = useParallax({ speed: [30, 60] })
    const refFour = useParallax({ speed: 20, translateY: 20 })
    const refFive = useParallax({ speed: 1, translateY: 1 })

    return (
        <>
            <section className="parallax-hero relative min-h-[80vh] max-h-[80vh] lg:h-[80vh] lg:min-h-[80vh] lg:max-h-[80vh] 2xl:h-[100vh] 2xl:min-h-[100vh] 2xl:max-h-[100vh] w-full h-full flex items-center justify-center overflow-hidden bg-black">
                <div
                    className="logo !absolute top-24 lg:top-28 z-[100] w-3/4 md:w-8/12 lg:w-full max-w-[225px] xl:max-w-[300px]"
                    ref={ref}
                >
                    <Link
                        {...{
                            to:
                                configs?.nodes[0]?.locale === 'en'
                                    ? '/'
                                    : `/${configs?.nodes[0]?.locale}`,
                        }}
                    >
                        <GatsbyImage
                            image={blackLogo}
                            alt={configs?.nodes[0]?.blackLogo?.alt}
                            className="mx-auto"
                        />
                    </Link>
                </div>

                {/* Artificial BG */}
                <div className="bg-white h-3/4 w-full absolute top-0 left-0" />
                <div className="bg-black h-1/4 w-full absolute bottom-0 left-0" />

                {/* assists height during scaling - duplicates largest image */}
                {/* <GatsbyImage image={assets.one} className="opacity-0 h-1/2" /> */}

                <StaticImage
                    src="../../assets/geolines/bkg-black-lines-left@2x.png"
                    className=" !absolute top-0 left-0 z-0 w-full"
                    alt={'Geolines Graphic'}
                />

                <div
                    className="!absolute lg:block w-full hidden top-[20%] left-1/4"
                    ref={refThree.ref}
                >
                    <GatsbyImage image={assets.three} alt={assets.three.alt} />
                </div>

                <div
                    className="mountain !absolute w-full z-0 bottom-72 2xl:bottom-96 3xl:bottom-[30rem] -right-1/4 min-w-[600px]"
                    ref={refFive.ref}
                >
                    <GatsbyImage image={assets.five} alt={assets.five.alt} />
                </div>

                {/* lake + trees */}
                <div
                    className="lake-trees !absolute w-full bottom-20 xs:bottom-16 md:bottom-14 lg:bottom-20 z-0 min-w-[800px]"
                    ref={refFour.ref}
                >
                    <GatsbyImage image={assets.four} alt={assets.four.alt} />
                </div>

                {/* <div className="black-bg-fill bg-black h-28 md:h-52 lg:h-48 absolute bottom-0 left-0 w-full" /> */}

                {/* White */}
                <div
                    className="white-rocks !absolute white-rocks bottom-24 sm:bottom-20 md:bottom-24 w-full lg:bottom-16 left-0 z-0 xl:bottom-16 3xl:!bottom-28"
                    ref={refTwo.ref}
                >
                    <GatsbyImage image={assets.two} alt={assets.two.alt} />
                </div>

                {/* Foremost - Rock */}
                <div
                    className="rock !absolute bottom-0 lg:left-0 lg:!-bottom-12 xl:!-bottom-40 z-0 -mb-10 2xl:!-bottom-32 min-w-[750px] 3xl:!-bottom-50"
                    ref={refOne.ref}
                >
                    <GatsbyImage image={assets.one} alt={assets.one.alt} />
                </div>

                <div className="w-full lg:flex items-center justify-center flex-col z-[0] absolute hidden lg:bg-transparent bg-black bottom-12 -mt-10">
                    {blade.addButton && (
                        <Button
                            {...{
                                color: 'primary',
                                clickHandler: () => setShow(true),
                                className: '!text-2xl lg:!text-3xl',
                            }}
                        >
                            {blade.heroButtonText}
                        </Button>
                    )}

                    {blade.enableAvailablePlatforms && (
                        <AvaliableOn
                            {...{
                                platforms: blade.platforms,
                                socials: blade.socials,
                                dateAvailable: blade?.dateAvailable,
                            }}
                        />
                    )}
                </div>
            </section>
            <div className="w-full flex items-center justify-center flex-col z-[0] relative lg:hidden bg-black -mt-10">
                {blade.addButton && (
                    <Button
                        {...{
                            color: 'primary',
                            clickHandler: () => setShow(true),
                            className: '!text-2xl lg:!text-3xl',
                        }}
                    >
                        {blade.heroButtonText}
                    </Button>
                )}

                {blade.enableAvailablePlatforms && (
                    <AvaliableOn
                        {...{
                            platforms: blade.platforms,
                            socials: blade.socials,
                        }}
                    />
                )}
            </div>
        </>
    )
}

export default ParallaxHero
