import React from "react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

import { useLightboxState } from "@context";

import Title from "@components/Title";
import Link from "@components/Link";
import Icon from "@components/Icon";

export default function YoutubeVideos({
  block: { title, videos, buttonLink, buttonText },
}) {
  const { setLightboxImages, setLightboxVideo, setLightboxOpen } =
    useLightboxState();

  const handleVideoClick = (video) => {
    setLightboxImages([]);
    setLightboxVideo(video);
    setLightboxOpen(true);
  };

  return (
    <div className="w-11/12 max-w-7xl mx-auto relative z-20">
      <Title
        {...{
          type: 2,
          className: "mt-0 text-5xl lg:text-6xl xl:text-6xl",
        }}
      >
        {title}
      </Title>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-12 gap-x-6 gap-y-3 my-10 max-w-7xl mx-auto">
        {videos?.map(({ title, thumbnail, video, embed }, i) => (
          <div
            key={i}
            className={`bg-black shadow-md w-full flex-1 flex flex-col ${
              i === 0
                ? "col-span-1 md:col-span-2 md:row-span-2 lg:col-span-6"
                : "col-span-1 lg:col-span-3"
            } mx-auto flex-nowrap text-white relative cursor-pointer aspect-video`}
            onClick={() => (!embed ? handleVideoClick(video) : true)}
          >
            <div className="w-full pt-[56.25%] h-full relative aspect-video">
              {embed ? (
                <iframe
                  type="text/html"
                  src={`https://www.youtube-nocookie.com/embed/${video.providerUid}?modestbranding=1&rel=0&playsinline=0&allowfullscreen=true`}
                  frameBorder="0"
                  className="w-full h-full absolute top-0 left-0"
                />
              ) : (
                <>
                  {thumbnail ? (
                    <GatsbyImage
                      image={getImage(thumbnail)}
                      objectFit="contain"
                      objectPosition={'center'}
                      alt={thumbnail?.alt ? thumbnail.alt : `Thumbnail Image`}
                      className="w-full h-full absolute top-0 left-0 align-top aspect-video"
                      style={{ position: "absolute" }}
                    />
                  ) : (
                    <img
                      src={video?.thumbnailUrl}
                      alt={title ? title : ``}
                      className="w-full h-full absolute top-0 left-0 align-top aspect-video"
                    />
                  )}
                </>
              )}
            </div>

            {!embed ? (
              <>
                <span className="flex items-center justify-center h-5 pl-1.5 pr-1 absolute top-4 right-4 text-xs text-center font-display ru-countach-font font-bold uppercase bg-youtube text-white rounded">
                  <Icon
                    {...{
                      name: "youtube",
                      className: "fill-current w-4 h-4 -ml-px",
                    }}
                  />
                </span>

                <span className="flex items-center justify-center w-12 h-12 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center border-2 border-white rounded-full">
                  <Icon
                    {...{
                      name: "play",
                      className:
                        "fill-current w-4 h-4 ml-0.5 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2",
                    }}
                  />
                </span>
              </>
            ) : null}
          </div>
        ))}
      </div>

      <div className="flex items-center justify-center">
        <Link
          {...{
            type: "button",
            external: true,
            to: buttonLink,
            className: "bg-primary text-xl xl:text-2xl text-black text-center",
            clipCircle: "wide",
          }}
        >
          <div className="flex items-center justify-center">
            {buttonText}
            <Icon
              {...{
                name: "youtube",
                className: "fill-current w-8 h-auto ml-2",
              }}
            />
          </div>
        </Link>
      </div>
    </div>
  );
}
